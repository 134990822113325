import { createTheme } from '@mui/material/styles';

import { ColorBlack, ColorWhite, fontFamily, fontSize } from '../../theme';

export const signInTheme = createTheme({
  palette: {
    mode: 'dark',
    common: {
      black: ColorBlack,
      white: ColorWhite,
    },
  },
  typography: {
    fontFamily,
    fontSize,
    allVariants: {
      color: ColorWhite,
    },
  },
});
