import { useEffect, useMemo } from 'react';
import { Navigate, Params, useLocation, useParams } from 'react-router-dom';

import { To } from '@remix-run/router';
import { isFunction } from 'lodash';

import { Configuration } from '../config';
import { stringifyLocationDescriptor } from '../services/urlService';

interface RedirectProps<P extends Record<string, string | undefined>> {
  to: To | ((params: Readonly<[P] extends [string] ? Params<P> : Partial<P>>) => To);
}

const Redirect = <P extends Record<string, string | undefined>>(props: RedirectProps<P>) => {
  const location = useLocation();
  const params = useParams<P>();

  const to = useMemo(() => isFunction(props.to) ? props.to(params) : props.to, [params, props]);

  useEffect(() => {
    const fromUrl = `${ Configuration.frontendUrlBase }${ stringifyLocationDescriptor(location) }`;
    const toUrl = `${ Configuration.frontendUrlBase }${ stringifyLocationDescriptor(to) }`;

    console.log(`[RedirectWithParam] Redirect: ${ fromUrl } -> ${ toUrl }`);
  }, [location, to]);

  return <Navigate replace={ true } to={ to } />;
};

export default Redirect;
