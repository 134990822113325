import { isEmpty, isNil, trim } from 'lodash';

import { Configuration } from '../config';
import { DefaultApiAxiosParamCreator, DefaultApiFactory, Configuration as OpenApiFpConfiguration } from '../openapi';

export const getRestApi = (accessToken?: string) => DefaultApiFactory(getConfiguration(accessToken));

export const getDefaultApiAxiosParamCreator = (accessToken?: string) => DefaultApiAxiosParamCreator(getConfiguration(accessToken));

const getConfiguration = (accessToken?: string): OpenApiFpConfiguration => new OpenApiFpConfiguration({
  basePath: `${ Configuration.backendUrlBase }/rest`,
  baseOptions: {
    headers: {
      authorization: isNil(accessToken) || isEmpty(trim(accessToken)) ? undefined : `${ !accessToken.startsWith('Bearer ') ? 'Bearer' : '' } ${ accessToken }`,
    },
  },
});
