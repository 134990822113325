import React, { FC, ReactNode } from 'react';

import { chain, CollectionChain, get, isArray, isNil } from 'lodash';

import Grid, { GridProps } from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { filterNil } from '../../services/arrayService';
import { Nullable } from '../../services/objectService';
import { getNationalFlagEmoji } from '../../services/stringService';
import ShowIf from '../showIf';
import ShowIfNotEmpty from '../showIf/notEmpty';

import SelectorMenuItem  from './menuItem';

interface SelectorMenuItemNameProps {
  additional?: ReactNode;
  additionalRenderRaw?: boolean;
  children: ReactNode;
  flexMain?: GridProps['flex'];
  justifyContent?: GridProps['justifyContent'];
  noWrap?: boolean;
}

const getChain = <T extends any>(items: CollectionChain<T> | Array<T> | undefined): CollectionChain<T> => isArray(items) || isNil(items) ? chain(items) : items;

export const SelectorMenuItemName: FC<SelectorMenuItemNameProps> = ({ children, additional, additionalRenderRaw, flexMain, justifyContent, noWrap }) => (
  <Grid alignItems="center" container={ true } gap={ 2 } justifyContent={ justifyContent ?? 'space-between' } wrap="nowrap">
    <Grid flex={ flexMain === 0 ? undefined : (flexMain ?? 1) }
          item={ true }
          lineHeight="24px"
          minHeight="24px"
          overflow={ noWrap ? 'hidden' : undefined }
          textOverflow={ noWrap ? 'ellipsis' : undefined }
          whiteSpace={ noWrap ? 'nowrap' : undefined }
    >
      { children }
    </Grid>
    <ShowIfNotEmpty value={ additional }>
      {
        additional => additionalRenderRaw
          ? additional
          : <Typography component="div" lineHeight="24px" minHeight="24px" variant="body2">{ additional }</Typography>
      }
    </ShowIfNotEmpty>
  </Grid>
);

interface SelectorMenuItemsSourceCountry {
  __typename: 'Country';
  countryCodeAlpha2: string;
  countryCodeAlpha3: string;
  shortName: string;
}

export const getSelectorMenuItemsCountry = <T extends SelectorMenuItemsSourceCountry>(
  items: CollectionChain<T> | Array<T> | undefined,
  valueProperty: keyof Pick<SelectorMenuItemsSourceCountry, 'countryCodeAlpha2' | 'countryCodeAlpha3'> = 'countryCodeAlpha2',
  disabled?: (item: T) => boolean,
  additional?: (item: T) => ReactNode
): Array<SelectorMenuItem<string>> => getChain(items)
  .map(it => ({
    value: it[valueProperty],
    disabled: disabled?.(it) ?? false,
    name: (
      <SelectorMenuItemName additional={ additional?.(it) }>
        { `${ getNationalFlagEmoji(it.countryCodeAlpha2, true) }${ it.shortName }` }
      </SelectorMenuItemName>
    ),
  }))
  .value();

interface SelectorMenuItemsSourceCompany {
  __typename: 'Company';
  companyId: string;
  fullName: string;
  countryByCountryCodeAlpha2: Nullable<Omit<SelectorMenuItemsSourceCountry, 'countryCodeAlpha3'>>;
}

export const getSelectorMenuItemsCompany = <T extends SelectorMenuItemsSourceCompany>(
  items: CollectionChain<T> | Array<T> | undefined,
  disabled?: (item: T) => boolean,
  additional?: (item: T) => ReactNode
): Array<SelectorMenuItem<string>> => getChain(items)
  .map(it => ({
    value: it.companyId,
    disabled: disabled?.(it) ?? false,
    name: (
      <SelectorMenuItemName additional={ additional?.(it) }>
        { `${ getNationalFlagEmoji(it.countryByCountryCodeAlpha2?.countryCodeAlpha2, true) }${ it.fullName }` }
      </SelectorMenuItemName>
    ),
  }))
  .value();

interface SelectorMenuItemsSourceEmployeeLevel {
  __typename: 'EmployeeLevel';
  employeeLevelId: string;
  displayName: string;
}

export const getSelectorMenuItemsEmployeeLevel = <T extends SelectorMenuItemsSourceEmployeeLevel>(
  items: CollectionChain<T> | Array<T> | undefined,
  disabled?: (item: T) => boolean,
  additional?: (item: T) => ReactNode
): Array<SelectorMenuItem<string>> => getChain(items)
  .map(it => ({
    value: it.employeeLevelId,
    disabled: disabled?.(it) ?? false,
    name: (
      <SelectorMenuItemName additional={ additional?.(it) }>
        { it.displayName }
      </SelectorMenuItemName>
    ),
  }))
  .value();

interface SelectorMenuItemsSourceContractType {
  __typename: 'ContractType';
  contractTypeId: string;
  displayName: string;
}

export const getSelectorMenuItemsContractType = <T extends SelectorMenuItemsSourceContractType>(
  items: CollectionChain<T> | Array<T> | undefined,
  disabled?: (item: T) => boolean,
  additional?: (item: T) => ReactNode
): Array<SelectorMenuItem<string>> => getChain(items)
  .map(it => ({
    value: it.contractTypeId,
    disabled: disabled?.(it) ?? false,
    name: (
      <SelectorMenuItemName additional={ additional?.(it) }>
        { it.displayName }
      </SelectorMenuItemName>
    ),
  }))
  .value();

export interface SelectorMenuItemsSourceBusinessStructureCommon {
  __typename: string;
  name: string;
  isActive: boolean;
}

export const getSelectorMenuItemsBusinessStructure = <T extends SelectorMenuItemsSourceBusinessStructureCommon>(
  items: CollectionChain<T> | Array<T> | undefined,
  disabled?: (item: T) => boolean,
  additional?: (item: T) => ReactNode,
): Array<SelectorMenuItem<string>> => filterNil(
  getChain(items)
    .orderBy(['isActive', 'name'], ['desc', 'asc'])
    .map(it => {
      let idProperty = '';

      switch (it.__typename) {
        case 'BusinessUnit':
          idProperty = 'businessUnitId';
          break;
        case 'Division':
          idProperty = 'divisionId';
          break;
        case 'Department':
          idProperty = 'departmentId';
          break;
        default:
          return null;
      }

      const _additional = (
        <Grid alignItems="center" container={ true } gap={ 1 } width="initial" wrap="nowrap">
          <ShowIf test={ !it.isActive }>
            <Typography color="error" component="div" lineHeight="24px" minHeight="24px" variant="overline">Disabled</Typography>
          </ShowIf>
          <Typography component="div" lineHeight="24px" minHeight="24px" variant="body2">
            { additional?.(it) }
          </Typography>
        </Grid>
      );

      return {
        value: get(it, idProperty),
        disabled: disabled?.(it) ?? false,
        name: (
          <SelectorMenuItemName additional={ _additional } additionalRenderRaw={ true }>
            { it.name }
          </SelectorMenuItemName>
        ),
      };
    })
    .value()
);

interface SelectorMenuItemsSourceUserRole {
  __typename: 'UserRole';
  roleName: string;
  displayName: string;
}

export const getSelectorMenuItemsUserRole = <T extends SelectorMenuItemsSourceUserRole>(
  items: CollectionChain<T> | Array<T> | undefined,
  disabled?: (item: T) => boolean,
  additional?: (item: T) => ReactNode
): Array<SelectorMenuItem<string>> => getChain(items)
  .map(it => ({
    value: it.roleName,
    disabled: disabled?.(it) ?? false,
    name: (
      <SelectorMenuItemName additional={ additional?.(it) }>
        { it.displayName }
      </SelectorMenuItemName>
    ),
  }))
  .value();
