import React, { Fragment, isValidElement, ReactElement } from 'react';

import { Decimal } from 'decimal.js';
import { cloneDeep, isEmpty, isEqual, isNil, isString, toNumber, toString, trim } from 'lodash';
import Pluralize from 'pluralize';

import { CountryCodeAlpha2Global } from '../const/countryCodeAlpha2';

import { isEmptySome, Nullable, NullOrUndefinedOr } from './objectService';

export const NonBreakingSpace = '\u00a0';

export const getNationalFlagEmoji = (countryCodeAlpha2: NullOrUndefinedOr<string>, appendSpace = false) => {
  if (window.navigator.userAgent.includes('Windows NT')) {
    // Windows does not support
    return '';
  }

  if (!countryCodeAlpha2) {
    return '';
  } else if (!/^[a-zA-Z]{2}$/.test(countryCodeAlpha2)) {
    return '';
  }

  let result: string;

  if (countryCodeAlpha2.toUpperCase() === CountryCodeAlpha2Global) {
    // 0x1F30F = Earth Globe Asia-australia
    result = String.fromCodePoint(parseInt('0x1F30F', 16));
  } else {
    // 0x1F1E6 = Regional Indicator Symbol Letter A
    result = String.fromCodePoint(...countryCodeAlpha2.toUpperCase().split('').map(char => 0x1F1E6 + char.charCodeAt(0) - 'A'.charCodeAt(0)));
  }

  return result + (appendSpace ? NonBreakingSpace : '');
};

interface FormatNumberOptions {
  prefix?: string;
  suffix?: string;
  omitZero?: boolean;
  fractionCount?: number;
  omitFractionCountWhenZero?: boolean;
  floor?: boolean;
  defaultValueIfNaN?: string;
}

export const formatNumber = (value: NullOrUndefinedOr<number | string | Decimal>, options?: FormatNumberOptions): NullOrUndefinedOr<string> => {
  if (value === null || value === undefined) {
    return value;
  }

  value = new Decimal(value);

  if (value.isNaN()) {
    return options?.defaultValueIfNaN;
  }

  if (options?.omitZero && value.equals(0)) {
    return '';
  }

  const s = String(value.toFixed(options?.fractionCount ?? 0, options?.floor ? Decimal.ROUND_FLOOR : Decimal.ROUND_HALF_UP)).split('.');
  let ret = String(s[0]).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

  if (s.length > 1 || (options?.fractionCount && !options.omitFractionCountWhenZero)) {
    ret += '.' + s[1];
  }

  return (options?.prefix ?? '') + ret + (options?.suffix ?? '');
};

export const formatNumberAsCurrency = (value: number | NullOrUndefinedOr<string>, currency = '$', options?: Omit<FormatNumberOptions, 'prefix'>) =>
  formatNumber(value, {
    ...options,
    prefix: `${ currency } `,
    fractionCount: 2,
  });

export const formatNumberAsFileSize = (valueByte: number | string | undefined) => {
  if (typeof valueByte === 'undefined') {
    return '';
  }

  const bytes = toNumber(valueByte);
  const suffix = ['bytes', 'KB', 'MB', 'GB'];
  let target = Math.floor(Math.log(bytes) / Math.log(1280));

  if (target >= suffix.length) {
    target = suffix.length - 1;
  }

  return `${ formatNumber(bytes / Math.pow(1024, Math.floor(target)), { fractionCount: 2, omitFractionCountWhenZero: true }) } ${ suffix[target] }`;
};

export const zeroPadding = (num: number | string, length: number) => {
  const pad = '000000000000000';

  num = toNumber(num);

  return num >= 0 ? (pad + num).slice(-length) : '-' + (pad + num * -1).slice(-length);
};

export const isNumeric = (value: NullOrUndefinedOr<string>, allowNegative: boolean) => {
  if (value === null || value === undefined) {
    return false;
  }

  const regExpPositive = /^(0|([1-9][0-9]*))$/;
  const regExpNegative = /^-[1-9][0-9]*$/;

  if (allowNegative) {
    return regExpPositive.test(value) || regExpNegative.test(value);
  }

  return regExpPositive.test(value);
};

export const getEmployeeNameWithNickname = (employee: NullOrUndefinedOr<{ fullName?: Nullable<string>; nickname?: Nullable<string> }>) => {
  if (!employee) {
    return '';
  }

  return `${ employee.fullName }${ !employee.nickname ? '' : ` (${ employee.nickname })` }`;
};

/**
 * Join words for English sentence.
 *
 * @example
 *   joinStringForEnglishSentence(['Red', 'Green', 'Blue']);
 *   // 'Red, Green and Blue'
 *
 *   joinStringForEnglishSentence(['Red', 'Green']);
 *   // 'Red and Blue'
 *
 *   joinStringForEnglishSentence(['Red']);
 *   // 'Red'
 *
 *   joinStringForEnglishSentence(['Red', 'Green', 'Blue'], ['"']);
 *   // '"Red", "Green" and "Blue"'
 *
 *   joinStringForEnglishSentence(['Red', 'Green', 'Blue'], ['<']);
 *   // '<Red<, <Green< and <Blue<'
 *
 *   joinStringForEnglishSentence(['Red', 'Green', 'Blue'], ['<', '>']);
 *   // '<Red>, <Green> and <Blue>'
 *
 *   joinStringForEnglishSentence(['Red', 'Green', 'Blue'], ['<', '>', '!']);
 *   // '<Red>, <Green> and <Blue>'  (Ignores '!' or later arguments)
 *
 *   joinStringForEnglishSentence(['Red', 'Green', 'Blue'], ['<"', '">',]);
 *   // '<"Red">, <"Green"> and <"Blue">'
 */
export function joinStringForEnglishSentence(words: NullOrUndefinedOr<Array<string>>, bracketsForEachItem?: undefined | Array<string>): string;

export function joinStringForEnglishSentence(elements: NullOrUndefinedOr<Array<ReactElement>>): Array<ReactElement>;

export function joinStringForEnglishSentence(items: NullOrUndefinedOr<Array<string | ReactElement>>, bracketsForEachItem: undefined | Array<string> = undefined): string | Array<ReactElement> {
  if (!items || items.length === 0) {
    return '';
  }

  let _items = cloneDeep(items.filter(w => isString(w) || isValidElement(w))).map(w => (isString(w) ? trim(w) : w));

  if (_items.length === 0) {
    return '';
  }

  // only when `items` is Array<string>
  if (bracketsForEachItem && bracketsForEachItem.length >= 1) {
    _items = cloneDeep(_items.map(w => `${ bracketsForEachItem[0] }${ w }${ bracketsForEachItem.length === 1 ? bracketsForEachItem[0] : bracketsForEachItem[1] }`));
  }

  if (isString(_items[0])) {
    return `${ _items.slice(0, _items.length - 1).join(', ') }${ _items.length >= 2 ? ' and ' : '' }${ _items[_items.length - 1] }`;
  } else if (isValidElement(_items[0])) {
    return _items.map((e, i) => (
      <Fragment key={ i }>
        { i === 0 || i === _items.length - 1 ? '' : ', ' }
        { i > 0 && i === _items.length - 1 ? ' and ' : '' }
        { _items[i] }
      </Fragment>
    ));
  }

  return '';
}

interface PluralOptions {
  noNumber?: boolean;
  useNoForZero?: boolean;
}

export function plural(count: number | NullOrUndefinedOr<string>, word: string, pluralOptions?: PluralOptions): string;

export function plural(count: number | NullOrUndefinedOr<string>, words: Array<string>, pluralOptions?: PluralOptions): string;

export function plural(count: number | NullOrUndefinedOr<string>, words: string | Array<string>, pluralOptions?: PluralOptions): string {
  let _count: number;

  if (isString(count)) {
    _count = parseInt(count, 10);
  } else {
    _count = count ?? 0;
  }

  let _words: Array<string>;

  if (isString(words)) {
    _words = [words];
  } else {
    _words = words;
  }

  const prefix = pluralOptions?.noNumber ? '' : pluralOptions?.useNoForZero && _count === 0 ? 'no ' : `${ _count } `;

  return `${ prefix }${ joinStringForEnglishSentence(_words.map(w => (_count === 1 ? w : Pluralize.plural(w)))) }`;
}

export const ordinal = (n: number) => {
  const s1 = toNumber(toString(n).slice(-1));
  const s2 = toNumber(toString(n).slice(-2));

  if (s2 >= 11 && s2 <= 13) {
    return `${ n }th`;
  } else if (s1 === 1) {
    return `${ n }st`;
  } else if (s1 === 2) {
    return `${ n }nd`;
  } else if (s1 === 3) {
    return `${ n }rd`;
  }

  return `${ n }th`;
};

export const capitalize = (value: NullOrUndefinedOr<string>) => isNil(value) ? '' : value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const emptyTo = <T extends string>(value: NullOrUndefinedOr<T>, replace = '') => isNil(value) || isEmpty(trim(value)) ? replace : value;

export const emptyToNull = <T extends string>(value: T | '') => isEmpty(trim(value)) ? null : value as T;

export const emptyToUndefined = <T extends string>(value: T | '') => isEmpty(trim(value)) ? undefined : value as T;

export const isEqualString = (a: NullOrUndefinedOr<string>, b: NullOrUndefinedOr<string>) => isEqual(emptyTo(a), emptyTo(b));

export const wrapText = (value: NullOrUndefinedOr<string | number>, wrapper: 'parentheses' | 'square' | 'curly' | 'angle' | 'single-quote' | 'double-quote') => {
  if (isNil(value) || isEmpty(trim(`${ value }`))) {
    return '';
  }

  switch (wrapper) {
    case 'parentheses':
      return `(${ value })`;
    case 'square':
      return `[${ value }]`;
    case 'curly':
      return `{${ value }}`;
    case 'angle':
      return `<${ value }>`;
    case 'single-quote':
      return `'${ value }'`;
    case 'double-quote':
      return `"${ value }"`;
  }
};

export const convertKana = (input: string, to: 'Hiragana' | 'Katakana') => {
  switch (to) {
    case 'Hiragana':
      return input.replace(/[\u30a1-\u30f6]/g, (match) => {
        const chr = match.charCodeAt(0) - 0x60;

        return String.fromCharCode(chr);
      });
    case 'Katakana':
      return input.replace(/[\u3041-\u3096]/g, (match) => {
        const chr = match.charCodeAt(0) + 0x60;

        return String.fromCharCode(chr);
      });
  }
};

const katakanaMap: Array<{ zen: string; han: string; }> = [
  { zen: 'ガ', han: 'ｶﾞ' }, { zen: 'ギ', han: 'ｷﾞ' }, { zen: 'グ', han: 'ｸﾞ' }, { zen: 'ゲ', han: 'ｹﾞ' }, { zen: 'ゴ', han: 'ｺﾞ' },
  { zen: 'ザ', han: 'ｻﾞ' }, { zen: 'ジ', han: 'ｼﾞ' }, { zen: 'ズ', han: 'ｽﾞ' }, { zen: 'ゼ', han: 'ｾﾞ' }, { zen: 'ゾ', han: 'ｿﾞ' },
  { zen: 'ダ', han: 'ﾀﾞ' }, { zen: 'ヂ', han: 'ﾁﾞ' }, { zen: 'ヅ', han: 'ﾂﾞ' }, { zen: 'デ', han: 'ﾃﾞ' }, { zen: 'ド', han: 'ﾄﾞ' },
  { zen: 'バ', han: 'ﾊﾞ' }, { zen: 'ビ', han: 'ﾋﾞ' }, { zen: 'ブ', han: 'ﾌﾞ' }, { zen: 'ベ', han: 'ﾍﾞ' }, { zen: 'ボ', han: 'ﾎﾞ' },
  { zen: 'パ', han: 'ﾊﾟ' }, { zen: 'ピ', han: 'ﾋﾟ' }, { zen: 'プ', han: 'ﾌﾟ' }, { zen: 'ペ', han: 'ﾍﾟ' }, { zen: 'ポ', han: 'ﾎﾟ' },
  { zen: 'ヴ', han: 'ｳﾞ' }, { zen: 'ヷ', han: 'ﾜﾞ' }, { zen: 'ヺ', han: 'ｦﾞ' },
  { zen: 'ア', han: 'ｱ' }, { zen: 'イ', han: 'ｲ' }, { zen: 'ウ', han: 'ｳ' }, { zen: 'エ', han: 'ｴ' }, { zen: 'オ', han: 'ｵ' },
  { zen: 'カ', han: 'ｶ' }, { zen: 'キ', han: 'ｷ' }, { zen: 'ク', han: 'ｸ' }, { zen: 'ケ', han: 'ｹ' }, { zen: 'コ', han: 'ｺ' },
  { zen: 'サ', han: 'ｻ' }, { zen: 'シ', han: 'ｼ' }, { zen: 'ス', han: 'ｽ' }, { zen: 'セ', han: 'ｾ' }, { zen: 'ソ', han: 'ｿ' },
  { zen: 'タ', han: 'ﾀ' }, { zen: 'チ', han: 'ﾁ' }, { zen: 'ツ', han: 'ﾂ' }, { zen: 'テ', han: 'ﾃ' }, { zen: 'ト', han: 'ﾄ' },
  { zen: 'ナ', han: 'ﾅ' }, { zen: 'ニ', han: 'ﾆ' }, { zen: 'ヌ', han: 'ﾇ' }, { zen: 'ネ', han: 'ﾈ' }, { zen: 'ノ', han: 'ﾉ' },
  { zen: 'ハ', han: 'ﾊ' }, { zen: 'ヒ', han: 'ﾋ' }, { zen: 'フ', han: 'ﾌ' }, { zen: 'ヘ', han: 'ﾍ' }, { zen: 'ホ', han: 'ﾎ' },
  { zen: 'マ', han: 'ﾏ' }, { zen: 'ミ', han: 'ﾐ' }, { zen: 'ム', han: 'ﾑ' }, { zen: 'メ', han: 'ﾒ' }, { zen: 'モ', han: 'ﾓ' },
  { zen: 'ヤ', han: 'ﾔ' }, { zen: 'ユ', han: 'ﾕ' }, { zen: 'ヨ', han: 'ﾖ' },
  { zen: 'ラ', han: 'ﾗ' }, { zen: 'リ', han: 'ﾘ' }, { zen: 'ル', han: 'ﾙ' }, { zen: 'レ', han: 'ﾚ' }, { zen: 'ロ', han: 'ﾛ' },
  { zen: 'ワ', han: 'ﾜ' }, { zen: 'ヲ', han: 'ｦ' }, { zen: 'ン', han: 'ﾝ' },
  { zen: 'ァ', han: 'ｧ' }, { zen: 'ィ', han: 'ｨ' }, { zen: 'ゥ', han: 'ｩ' }, { zen: 'ェ', han: 'ｪ' }, { zen: 'ォ', han: 'ｫ' },
  { zen: 'ッ', han: 'ｯ' }, { zen: 'ャ', han: 'ｬ' }, { zen: 'ュ', han: 'ｭ' }, { zen: 'ョ', han: 'ｮ' },
  { zen: 'ー', han: 'ｰ' }, { zen: '（', han: '(' }, { zen: '）', han: ')' }, { zen: '・', han: '･' }, { zen: '　', han: ' ' }, { zen: '゛', han: 'ﾞ' }, { zen: '゜', han: 'ﾟ' },
];

export const convertKatakana = (input: string, mode: 'FullToHalf' | 'HalfToFull', options?: { fromHiragana: boolean; replaceUnmatchedToEmpty: boolean; }) => {
  const _input = options?.fromHiragana ? convertKana(input, 'Katakana') : input;

  return _input
    .split('')
    .map(source => {
      const sameAsTarget = mode === 'FullToHalf'
        ? katakanaMap.findIndex(it => it.han === source)
        : katakanaMap.findIndex(it => it.zen === source);

      if (sameAsTarget >= 0) {
        return source;
      }

      const target = mode === 'FullToHalf'
        ? katakanaMap.find(it => it.zen === source)?.han
        : katakanaMap.find(it => it.han === source)?.zen;

      return target ?? (options?.replaceUnmatchedToEmpty ? '' : source);
    })
    .join('');
};

/**
 * @see https://mui.com/material-ui/react-avatar/#letter-avatars
 * @param string any string
 */
export const stringToColor = (string: NullOrUndefinedOr<string>) => {
  if (isNil(string) || isEmptySome(true, string)) {
    return '#bdbdbd';
  }
  
  let hash = 0;

  /* eslint-disable no-bitwise */
  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;

    color += `00${ value.toString(16) }`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
