import { forwardRef, MouseEventHandler } from 'react';
import { LinkProps as RouterLinkProps, useHref, useLinkClickHandler } from 'react-router-dom';

import Link, { LinkProps } from '@mui/material/Link';

export interface ReactRouterLinkProps extends LinkProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  to: RouterLinkProps['to'];
  replace?: RouterLinkProps['replace'];
  state?: any;
}

const ReactRouterLink = forwardRef<HTMLAnchorElement, ReactRouterLinkProps>(({ to, onClick, ...props }, ref) => {
  const href = useHref(to);
  const handleClick = useLinkClickHandler(to, {
    replace: props.replace ?? false,
    state: props.state,
    target: props.target,
  });

  return (
    <Link { ...props }
          ref={ ref }
          href={ href }
          onClick={
            event => {
              onClick?.(event);

              if (!event.defaultPrevented) {
                handleClick(event);
              }
            }
          }
    />
  );
});

export default ReactRouterLink;
