import { createElement } from 'react';

import Button, { ButtonProps as OriginalButtonProps } from '@mui/material/Button';

type ButtonProps = Omit<OriginalButtonProps, 'color'>;

export const PrimaryButton = (props: Omit<ButtonProps, 'color' | 'variant'>) => createElement(Button, { ...props, color: 'primary', variant: 'contained' });

export const SecondaryButton = (props: Omit<ButtonProps, 'color'> & { variant?: Exclude<ButtonProps['variant'], 'contained'> }) => createElement(Button, { ...props, color: 'secondary' });

export const ErrorButton = (props: Omit<ButtonProps, 'color' | 'variant'>) => createElement(Button, { ...props, color: 'error', variant: 'contained' });
