import React, { useCallback, useEffect } from 'react';

import { FallbackRender } from '@sentry/react';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { PrimaryButton } from './components/button';
import LoadingBar from './components/loading';
import { theme } from './theme';

export const SentryErrorFallback: FallbackRender = ({ error, componentStack, resetError }) => {
  const backToPrevious = useCallback(() => {
    resetError();
    location.replace('/dashboard');
  }, [resetError]);

  useEffect(() => {
    if (error.name === 'ChunkLoadError') {
      window.location.reload();
    }
  }, [error.name]);
  
  return (
    <ThemeProvider theme={ theme }>
      <Box sx={ { padding: '16px' } }>
        {
          error.name === 'ChunkLoadError'
            ? <LoadingBar />
            : (
              <>
                <Typography gutterBottom={ true } variant="h4">Sorry for inconvenience</Typography>
                <Typography gutterBottom={ true } variant="body1">We faced an issue.</Typography>
                <Typography color="error" gutterBottom={ true } variant="body1">{ error.toString() }</Typography>
                {
                    !componentStack
                      ? ''
                      : (
                        <Accordion defaultExpanded={ true }>
                          <AccordionSummary>Stacktrace</AccordionSummary>
                          <AccordionDetails sx={ { overflowX: 'scroll' } }>
                            <pre style={ { margin: 0 } }>{ componentStack.trim().split('\n').map(it => it.trim()).join('\n') }</pre>
                          </AccordionDetails>
                        </Accordion>
                      )
                  }
                <Box sx={ { margin: '8px 0' } }>
                  <PrimaryButton onClick={ backToPrevious }>Go home</PrimaryButton>
                </Box>
              </>
            )
      }
      </Box>
    </ThemeProvider>
  );
};
