/* eslint-disable import/no-default-export */

export type PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'AppForceReload',
      'Bank',
      'BusinessUnit',
      'City',
      'Company',
      'CompanyExternalAccountDisable',
      'CompanyGlobalHr',
      'CompanyGroup',
      'CompanyLocalHr',
      'ContractType',
      'Country',
      'Currency',
      'Department',
      'Division',
      'EmailDomain',
      'Employee',
      'EmployeeCurrentPosition',
      'EmployeeDaysByContractType',
      'EmployeeEmergencyContact',
      'EmployeeFile',
      'EmployeeGroup',
      'EmployeeGroupLatest',
      'EmployeeGroupList',
      'EmployeeIdType',
      'EmployeeLevel',
      'EmployeeListCustomFilter',
      'EmployeeListCustomFilterRole',
      'EmployeeResignationReason',
      'EmployeeResignationType',
      'EmployeeStatus',
      'EmployeeTpHistory',
      'EmployeeTransferHistory',
      'EmployeeWorkingDayStatisticsAll',
      'ExportEmployeeStatus',
      'ExternalAccountEmployee',
      'ExternalAccountType',
      'FlywaySchemaHistory',
      'GoogleJwk',
      'GoogleWorkspaceOrgUnit',
      'ImportEmployeeStatus',
      'Office',
      'OfficeWeather',
      'PerformanceReviewEvaluation',
      'PerformanceReviewEvaluationCompetency',
      'PerformanceReviewEvaluationEvaluator',
      'PerformanceReviewEvaluationFeedback',
      'PerformanceReviewEvaluationKpiAssessment',
      'PerformanceReviewEvaluationKpiDescription',
      'PerformanceReviewEvaluationKpiResult',
      'PerformanceReviewEvaluationKpiTarget',
      'PerformanceReviewEvaluationOneOnOne',
      'PerformanceReviewEvaluationQualitativeAssessment',
      'PerformanceReviewEvaluationQualitativeDescription',
      'PerformanceReviewEvaluationValue',
      'PerformanceReviewMasterCompetency',
      'PerformanceReviewMasterCompetencyCommon',
      'PerformanceReviewMasterCompetencyCommonDescription',
      'PerformanceReviewMasterCompetencyCount',
      'PerformanceReviewMasterCompetencyDetail',
      'PerformanceReviewMasterCompetencyDetailDescription',
      'PerformanceReviewMasterCompetencyRole',
      'PerformanceReviewMasterDefault',
      'PerformanceReviewMasterEvaluationViewer',
      'PerformanceReviewMasterExcludedEmployee',
      'PerformanceReviewMasterOneOnOne',
      'PerformanceReviewMasterOneOnOneDetail',
      'PerformanceReviewMasterRating',
      'PerformanceReviewMasterRatingDescription',
      'PerformanceReviewMasterUsage',
      'PerformanceReviewMasterValue',
      'PerformanceReviewMasterValueCount',
      'PerformanceReviewMasterValueDescription',
      'PerformanceReviewMasterWeighting',
      'PerformanceReviewMasterWeightingDetail',
      'PersonalIdentityType',
      'ProbationReviewEnable',
      'ProbationReviewEnableView',
      'ProbationReviewEvaluation',
      'ProbationReviewEvaluationResultItem',
      'ProbationReviewEvaluationResultScoreCompetency',
      'ProbationReviewEvaluationResultScoreValue',
      'ProbationReviewEvaluationReviewer',
      'ProbationReviewMasterItem',
      'ProbationReviewMasterItemDescription',
      'Query',
      'RelationBusinessUnitDivision',
      'RelationBusinessUnitDivisionDepartment',
      'RelationCityCompany',
      'RelationCompanyBusinessUnit',
      'RelationCompanyGroupEmailDomain',
      'RelationCompanyOffice',
      'RelationCurrencyCountry',
      'RelationEmergencyContact',
      'RelationEmployeeUserAccount',
      'UserAccount',
      'UserAccountAccessHistory',
      'UserAccountPin',
      'UserForceLogout',
      'UserHrManagingCompany',
      'WorldPlace',
      'WorldPlaceWeather',
    ];
  };
};

const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'AppForceReload',
      'Bank',
      'BusinessUnit',
      'City',
      'Company',
      'CompanyExternalAccountDisable',
      'CompanyGlobalHr',
      'CompanyGroup',
      'CompanyLocalHr',
      'ContractType',
      'Country',
      'Currency',
      'Department',
      'Division',
      'EmailDomain',
      'Employee',
      'EmployeeCurrentPosition',
      'EmployeeDaysByContractType',
      'EmployeeEmergencyContact',
      'EmployeeFile',
      'EmployeeGroup',
      'EmployeeGroupLatest',
      'EmployeeGroupList',
      'EmployeeIdType',
      'EmployeeLevel',
      'EmployeeListCustomFilter',
      'EmployeeListCustomFilterRole',
      'EmployeeResignationReason',
      'EmployeeResignationType',
      'EmployeeStatus',
      'EmployeeTpHistory',
      'EmployeeTransferHistory',
      'EmployeeWorkingDayStatisticsAll',
      'ExportEmployeeStatus',
      'ExternalAccountEmployee',
      'ExternalAccountType',
      'FlywaySchemaHistory',
      'GoogleJwk',
      'GoogleWorkspaceOrgUnit',
      'ImportEmployeeStatus',
      'Office',
      'OfficeWeather',
      'PerformanceReviewEvaluation',
      'PerformanceReviewEvaluationCompetency',
      'PerformanceReviewEvaluationEvaluator',
      'PerformanceReviewEvaluationFeedback',
      'PerformanceReviewEvaluationKpiAssessment',
      'PerformanceReviewEvaluationKpiDescription',
      'PerformanceReviewEvaluationKpiResult',
      'PerformanceReviewEvaluationKpiTarget',
      'PerformanceReviewEvaluationOneOnOne',
      'PerformanceReviewEvaluationQualitativeAssessment',
      'PerformanceReviewEvaluationQualitativeDescription',
      'PerformanceReviewEvaluationValue',
      'PerformanceReviewMasterCompetency',
      'PerformanceReviewMasterCompetencyCommon',
      'PerformanceReviewMasterCompetencyCommonDescription',
      'PerformanceReviewMasterCompetencyCount',
      'PerformanceReviewMasterCompetencyDetail',
      'PerformanceReviewMasterCompetencyDetailDescription',
      'PerformanceReviewMasterCompetencyRole',
      'PerformanceReviewMasterDefault',
      'PerformanceReviewMasterEvaluationViewer',
      'PerformanceReviewMasterExcludedEmployee',
      'PerformanceReviewMasterOneOnOne',
      'PerformanceReviewMasterOneOnOneDetail',
      'PerformanceReviewMasterRating',
      'PerformanceReviewMasterRatingDescription',
      'PerformanceReviewMasterUsage',
      'PerformanceReviewMasterValue',
      'PerformanceReviewMasterValueCount',
      'PerformanceReviewMasterValueDescription',
      'PerformanceReviewMasterWeighting',
      'PerformanceReviewMasterWeightingDetail',
      'PersonalIdentityType',
      'ProbationReviewEnable',
      'ProbationReviewEnableView',
      'ProbationReviewEvaluation',
      'ProbationReviewEvaluationResultItem',
      'ProbationReviewEvaluationResultScoreCompetency',
      'ProbationReviewEvaluationResultScoreValue',
      'ProbationReviewEvaluationReviewer',
      'ProbationReviewMasterItem',
      'ProbationReviewMasterItemDescription',
      'Query',
      'RelationBusinessUnitDivision',
      'RelationBusinessUnitDivisionDepartment',
      'RelationCityCompany',
      'RelationCompanyBusinessUnit',
      'RelationCompanyGroupEmailDomain',
      'RelationCompanyOffice',
      'RelationCurrencyCountry',
      'RelationEmergencyContact',
      'RelationEmployeeUserAccount',
      'UserAccount',
      'UserAccountAccessHistory',
      'UserAccountPin',
      'UserForceLogout',
      'UserHrManagingCompany',
      'WorldPlace',
      'WorldPlaceWeather',
    ],
  },
};

export default result;
