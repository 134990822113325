import { isNil } from 'lodash';

type LocalStorageKeys = 'SIGN_IN_EMAIL' | 'ADD_EMPLOYEE_DRAFT' | 'FONT_FAMILY' | 'FONT_SIZE';

type SessionStorageKeys = 'PERFORMANCE_REVIEW_EVALUATION_COMMON_QUERY';

type StorageKeys = LocalStorageKeys | SessionStorageKeys;

class StorageService<TStorageKeys extends StorageKeys, TRemovableStorageKeys extends StorageKeys = TStorageKeys> {
  private readonly storage: Storage;

  constructor(private name: 'localStorage' | 'sessionStorage') {
    this.storage = window[name];
  }

  public load<T = string>(key: TStorageKeys, jsonToBeParsed = false): null | T {
    const value = this.storage.getItem(key);

    if (isNil(value)) {
      return null;
    } else if (!jsonToBeParsed) {
      return (value as unknown) as T;
    } else {
      return JSON.parse(value) as T;
    }
  }

  public save(key: TStorageKeys, value: any) {
    if (typeof value === 'string') {
      this.storage.setItem(key, value);
    } else {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }

  public remove(key: TRemovableStorageKeys) {
    this.storage.removeItem(key);
  }

  public clear() {
    this.storage.removeItem('ADD_EMPLOYEE_DRAFT');
  }
}

export const LocalStorageService = new StorageService<LocalStorageKeys>('localStorage');

export const SessionStorageService = new StorageService<SessionStorageKeys>('sessionStorage');
