import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { cloneDeep, isEmpty, trim } from 'lodash';
import { DateTime } from 'luxon';

import CancelIcon from '@mui/icons-material/Cancel';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SecondaryButton } from '../../components/button';
import { Configuration } from '../../config';
import { setPopupMessage, setSignInStatus } from '../../globalState';
import { SignInStatus } from '../../globalState/signInStatusProps';
import useFocus from '../../hooks/useFocus';
import useTokenCookie from '../../hooks/useTokenCookie';
import logo_white from '../../images/logo_white.svg';
import { getNow } from '../../services/dateService';
import { isEmptySome } from '../../services/objectService';
import { getDefaultApiAxiosParamCreator, getRestApi } from '../../services/restApiService';
import { sendClientErrorToSlack } from '../../services/slackService';
import { LocalStorageService } from '../../services/storageService';
import { NonBreakingSpace, wrapText } from '../../services/stringService';

import css from './signIn.module.scss';
import { signInTheme } from './theme';

const restApi = getRestApi();

const initialPIN = '';

const PageSignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { rawToken, setTokenToCookie, token } = useTokenCookie();

  const [now, setNow] = useState<DateTime>(getNow());
  const [inputEmailAddress, setInputEmailAddress] = useState<string>(LocalStorageService.load('SIGN_IN_EMAIL') ?? '');
  const [pinRef, setPINRef] = useState<string>();
  const [pinCreated, setPINCreated] = useState<string>();
  const [inputPIN, setInputPIN] = useState(initialPIN);
  const [processingDomainCheck, setProcessingDomainCheck] = useState(false);
  const [processingPINLogin, setProcessingPINLogin] = useState(false);

  const [inputEmailAddressRef, setInputEmailAddressFocus] = useFocus();
  const [inputPINRef, setInputPINRefFocus] = useFocus();

  useEffect(() => {
    const intervalGetNow = () => {
      const _now = getNow();

      setNow(_now);
    };
    const intervalId = window.setInterval(intervalGetNow, 1000);

    intervalGetNow();

    return () => window.clearInterval(intervalId);
  }, []);

  const clearErrorQuery = useCallback(() => {
    const existingQuery = new URLSearchParams(location.search);

    existingQuery.delete('error');
    existingQuery.delete('email');
    navigate({ ...location, search: existingQuery.toString() }, { replace: true });
  }, [location, navigate]);

  const executeDomainCheck = useCallback(async () => {
    try {
      if (processingDomainCheck) {
        return;
      }

      let _hasError = false;

      setPopupMessage(null);
      clearErrorQuery();

      if (isEmptySome(true, inputEmailAddress)) {
        _hasError = true;
        window.setTimeout(() => {
          setInputEmailAddressFocus();
        });
      }

      if (_hasError) {
        setPopupMessage({ severity: 'warning', message: 'Required fields are not filled.' });

        return false;
      }

      setProcessingDomainCheck(true);
      LocalStorageService.save('SIGN_IN_EMAIL', inputEmailAddress);

      const query = new URLSearchParams(location.search);
      const response = await restApi.userDomainCheck({
        emailAddress: inputEmailAddress,
        forcePin: query.get('forcePin') === 'true',
      });
      
      if (response.data.isSSO) {
        window.location.replace((await restApi.userSsoGenerateUrl(window.location.toString(), inputEmailAddress)).data.url);

        return true;
      } else {
        setPINRef(response.data.pinRef);
        setPINCreated(response.data.pinCreated);
        window.setTimeout(() => {
          setInputPINRefFocus();
        });
        setProcessingDomainCheck(false);
      }
    } catch (e) {
      console.error(e);
      setPopupMessage({ severity: 'error', message: 'Failed to sign in.' });
      setProcessingDomainCheck(false);
    }

    return false;
  }, [clearErrorQuery, inputEmailAddress, location.search, processingDomainCheck, setInputEmailAddressFocus, setInputPINRefFocus]);

  const countdownForResendPin = useMemo(() => {
    if (!pinCreated) {
      return 0;
    }

    return Math.max(0, Math.floor(DateTime.fromISO(pinCreated).diff(now.minus({ minute: 1 }), 'seconds').as('seconds')));
  }, [now, pinCreated]);
  
  const executeApiLoginByEmail = useCallback(async () => {
    try {
      if (processingPINLogin) {
        return;
      }

      let _hasError = false;

      setPopupMessage(null);
      clearErrorQuery();

      if (isEmptySome(true, inputEmailAddress)) {
        _hasError = true;
        window.setTimeout(() => {
          setInputEmailAddressFocus();
        });
      }

      if (_hasError) {
        setPopupMessage({ severity: 'warning', message: 'Required fields are not filled.' });

        return;
      }

      if (!pinRef && await executeDomainCheck()) {
        return;
      }

      if (!pinRef) {
        return;
      }

      _hasError = false;
      setPopupMessage(null);

      if (isEmptySome(true, inputPIN[0], inputPIN[1], inputPIN[2], inputPIN[3], inputPIN[4], inputPIN[5])) {
        window.setTimeout(() => {
          setInputPINRefFocus();
        });
        _hasError = true;
      }

      if (_hasError) {
        setPopupMessage({ severity: 'warning', message: 'Required fields are not filled.' });

        return;
      }

      setProcessingPINLogin(true);

      const url = (await getDefaultApiAxiosParamCreator(undefined).userAuthenticate(
        inputEmailAddress,
        pinRef,
        inputPIN,
        window.location.toString(),
      )).url;

      window.location.replace(`${ Configuration.backendUrlBase }/rest${ url }`);

      return;
    } catch (e) {
      console.error(e);
      setPopupMessage({ severity: 'error', message: 'Failed to sign in.' });
      setInputPIN(cloneDeep(initialPIN));
      window.setTimeout(() => {
        setInputPINRefFocus();
      }, 100);
    } finally {
      setProcessingPINLogin(false);
    }
  }, [
    clearErrorQuery,
    inputEmailAddress,
    executeDomainCheck,
    pinRef,
    processingPINLogin,
    inputPIN,
    setInputEmailAddressFocus,
    setInputPINRefFocus,
  ]);

  useEffect(() => {
    if (!pinRef) {
      setInputPIN(initialPIN);
      setPINCreated(undefined);
    }
  }, [pinRef]);

  useEffect(() => {
    if (!isEmpty(trim(location.search))) {
      const searchParams = new URLSearchParams(location.search);

      if (searchParams.has('token')) {
        // success
        const paramToken = searchParams.get('token') ?? '';

        setTokenToCookie(paramToken);
        setSignInStatus({ status: SignInStatus.SIGNED_IN });
      } else if (searchParams.has('error')) {
        // failed
        const error = searchParams.get('error') ?? '';

        sendClientErrorToSlack({ type: 'Error', error: new Error(error) }, token, rawToken).then();

        switch (error) {
          case 'USER_NOT_FOUND':
            setPopupMessage({ severity: 'error', message: 'No user found.' });
            break;
          case 'USER_DISABLED':
            setPopupMessage({ severity: 'error', message: 'User is disabled.' });
            break;
          case 'USER_ASSOCIATED_TO_ANOTHER_ACCOUNT':
            setPopupMessage({ severity: 'error', message: 'The G Suite email address is not related to your MED email address. Ask your HR to fix it.' });
            break;
          default:
            setPopupMessage({ severity: 'error', message: `Failed to sign in with Google. ${ wrapText(error, 'parentheses') }` });
            break;
        }
      }
    }
  }, [location.search, setTokenToCookie, token, rawToken]);

  return (
    <ThemeProvider theme={ signInTheme }>
      <Box className={ css.wrapper }>
        <Grid alignItems="center" container={ true } height="100%" justifyContent="center">
          <Grid item={ true } margin={ 2 }>
            <Box className={ css.contents }>
              <Box marginBottom={ 3 } sx={ { userSelect: 'none' } } textAlign="center">
                <Box>
                  <img alt="AnyMind" height={ 34 } src={ logo_white } />
                </Box>
                <Typography component="div" lineHeight={ 1 } variant="overline">Master Employee Database</Typography>
              </Box>
              <Box>
                <TextField autoComplete="email"
                           disabled={ processingDomainCheck || processingPINLogin || !!pinRef }
                           fullWidth={ true }
                           inputProps={ { 'data-1p-ignore': 'false', 'data-lpignore': 'false' } }
                           inputRef={ inputEmailAddressRef }
                           label="Email address"
                           name="emailAddress"
                           required={ true }
                           size="small"
                           value={ inputEmailAddress }
                           onChange={ event => setInputEmailAddress(event.target.value) }
                           onKeyDownCapture={ event => event.key === 'Enter' && !isEmptySome(true, inputEmailAddress) && executeDomainCheck() }
                />
              </Box>
              <Collapse in={ !!pinRef }>
                <Box marginTop={ 2 }>
                  <TextField autoComplete="no"
                             disabled={ processingDomainCheck || processingPINLogin }
                             fullWidth={ true }
                             InputProps={
                               {
                                 endAdornment: (
                                   <InputAdornment position="end">
                                     <IconButton disabled={ processingDomainCheck || processingPINLogin } size="small" onClick={ () => setPINRef(undefined) }>
                                       <CancelIcon fontSize="small" />
                                     </IconButton>
                                   </InputAdornment>
                                 ),
                               }
                             }
                             inputProps={ { 'data-1p-ignore': 'true', 'data-lpignore': 'true', style: { textAlign: 'center' }, maxLength: 6 } }
                             inputRef={ inputPINRef }
                             label={ `Input PIN sent by email (ref: ${ pinRef })` }
                             required={ true }
                             size="small"
                             value={ inputPIN }
                             onChange={ event => setInputPIN(event.target.value.replaceAll(/\D/g, '')) }
                             onKeyDownCapture={ event => event.key === 'Enter' && !isEmptySome(true, inputPIN) && executeApiLoginByEmail() }
                  />
                </Box>
              </Collapse>
              <Box marginTop={ 2 }>
                <Button disabled={ processingDomainCheck || processingPINLogin || isEmptySome(true, inputEmailAddress) }
                        fullWidth={ true }
                        startIcon={ (processingDomainCheck && !pinRef) || processingPINLogin ? <CircularProgress size={ 24 } /> : undefined }
                        variant="contained"
                        onClick={ executeApiLoginByEmail }
                >
                  Sign in with&nbsp;
                  { pinRef ? 'PIN' : 'Email' }
                </Button>
              </Box>
              <Collapse in={ !!pinRef }>
                <Box marginTop={ 2 }>
                  <SecondaryButton disabled={ processingDomainCheck || processingPINLogin || countdownForResendPin > 0 }
                                   fullWidth={ true }
                                   startIcon={ processingDomainCheck ? <CircularProgress size={ 24 } /> : undefined }
                                   variant="text"
                                   onClick={ executeDomainCheck }
                  >
                    Resent PIN
                    { countdownForResendPin === 0 ? '' : NonBreakingSpace + wrapText(countdownForResendPin, 'parentheses') }
                  </SecondaryButton>
                </Box>
              </Collapse>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default PageSignIn;
