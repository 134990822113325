import React, { FC } from 'react';

import { omit } from 'lodash';

import Box, { BoxProps } from '@mui/material/Box';

import { NullOrUndefinedOr } from '../services/objectService';

interface RenderHtmlProps extends Omit<BoxProps, 'children'> {
  noSanitize?: boolean;
  value: NullOrUndefinedOr<string>;
}

const RenderHtml: FC<RenderHtmlProps> = props => {
  const replaceList: Array<{ searchVale: RegExp; replaceValue: string }> = [
    { searchVale: /\n /g, replaceValue: '\n&nbsp;' },
    { searchVale: /\n/g, replaceValue: '<br />' },
  ];

  if (!props.noSanitize) {
    replaceList.unshift({ searchVale: /&/g, replaceValue: '&amp;' }, { searchVale: /</g, replaceValue: '&lt;' }, { searchVale: />/g, replaceValue: '&gt;' });
  }

  const replaced = replaceList.reduce((value, replaceArgs) => value.replace(replaceArgs.searchVale, replaceArgs.replaceValue), props.value ?? '');

  return <Box dangerouslySetInnerHTML={ { __html: replaced } } { ...omit(props, ['value', 'noSanitize']) } />;
};

export default RenderHtml;
