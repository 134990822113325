import { isNil, toNumber } from 'lodash';
import { DateTime } from 'luxon';

import { createTheme } from '@mui/material/styles';
import { DatePickerProps, DatePickerSlotsComponents, DateTimePickerProps, StaticDatePickerProps } from '@mui/x-date-pickers';

import { DATE_FORMAT_DISPLAY, DATETIME_FORMAT_DISPLAY } from './services/dateService';
import { LocalStorageService } from './services/storageService';

const ColorBackground = 'rgb(246, 248, 250)';
const ColorTableRowHead = 'rgb(239, 239, 239)';

export const ColorTableRowSelected = 'rgb(255, 253, 231)';

export const ColorTableRowSelectedHover = 'rgb(255, 251, 206)';

export const ColorBorder1 = 'rgb(224, 224, 224)';

export const ColorBorder2 = 'rgb(222, 229, 236)';

export const ColorAlert = 'rgb(255, 95, 95)';

export const ColorBlack = 'rgb(39, 49, 59)';

export const ColorBrand = 'rgb(64, 184, 124)';

export const ColorCaution = 'rgb(255, 182, 61)';

export const ColorSupport = 'rgb(56, 146, 229)';

export const ColorWhite = 'rgb(255, 255, 255)';

export const ColorTextBoxBorder = 'rgba(0, 0, 0, 0.23)';

export const ColorSubText = 'rgb(110, 124, 137)';

export const addAlphaToRgbColor = (rgb: string, alpha: number) => rgb.replace(/^rgb\((.+)\)$/, `rgba($1, ${ alpha })`);

export const convertHtmlColorToRgbColor = (html: string, alpha?: number) => {
  try {
    if (!/^#/.test(html)) {
      return isNil(alpha) ? html : addAlphaToRgbColor(html, alpha);
    }

    const hexValue = html.replace(/^#/, '');
    const [r, g, b] = [parseInt(hexValue.slice(0, 2), 16), parseInt(hexValue.slice(2, 4), 16), parseInt(hexValue.slice(4, 6), 16)];
    const decValue = `rgb(${ r }, ${ g }, ${ b })`;

    return isNil(alpha) ? decValue : addAlphaToRgbColor(decValue, alpha);
  } catch (e) {
    return html;
  }
};

export const fontFamily = LocalStorageService.load('FONT_FAMILY') ?? '"Helvetica", "Arial", sans-serif';

export const fontSize = toNumber(LocalStorageService.load('FONT_SIZE') ?? 14);

export type XDatePickersThemeComponentProps = {
  MuiDatePicker: { defaultProps: Partial<DatePickerProps<DateTime>> };
  MuiDateTimePicker: { defaultProps: Partial<DateTimePickerProps<DateTime>> };
  MuiStaticDatePicker: { defaultProps: Partial<StaticDatePickerProps<DateTime>> };
}

// noinspection JSUnusedGlobalSymbols
const XDatePickersThemeComponents: Partial<XDatePickersThemeComponentProps> = {
  MuiDatePicker: {
    defaultProps: {
      dayOfWeekFormatter: (_, date) => `${ date.toFormat('ccc') }`,
      format: DATE_FORMAT_DISPLAY,
    },
  },
  MuiDateTimePicker: {
    defaultProps: {
      dayOfWeekFormatter: (_, date) => `${ date.toFormat('ccc') }`,
      format: DATETIME_FORMAT_DISPLAY,
    },
  },
  MuiStaticDatePicker: {
    defaultProps: {
      dayOfWeekFormatter: (_, date) => `${ date.toFormat('ccc') }`,
      displayStaticWrapperAs: 'desktop',
      orientation: 'landscape',
      showDaysOutsideCurrentMonth: true,
    },
  },
};

export const theme = createTheme({
  palette: {
    mode: 'light',
    common: {
      black: ColorBlack,
      white: ColorBackground,
    },
    primary: {
      main: ColorBrand,
      contrastText: ColorWhite,
    },
    secondary: {
      main: ColorSubText,
    },
  },
  typography: {
    fontFamily,
    fontSize,
    h1: {
      color: ColorBlack,
      fontWeight: '700',
    },
    h2: {
      color: ColorBlack,
      fontWeight: '600',
    },
    h3: {
      color: ColorBlack,
      fontWeight: '500',
    },
    h4: {
      color: ColorBlack,
      fontWeight: '500',
    },
    h5: {
      color: ColorBlack,
      fontWeight: '400',
    },
    h6: {
      color: ColorBlack,
      fontWeight: '400',
    },
    body1: {
      color: ColorBlack,
      fontWeight: '400',
    },
    body2: {
      color: ColorSubText,
      fontWeight: '400',
    },
    overline: {
      fontFamily: '"Roboto", monospace',
    },
  },
  components: {
    ...XDatePickersThemeComponents as DatePickerSlotsComponents<any>,
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            color: ColorBlack,
          },
          '@font-face': [{ fontFamily }],
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          '&:not(:last-of-type):not(:first-of-type)': {
            borderBottom: 'none',
          },
          '&:first-of-type:not(:last-of-type)': {
            borderBottom: 'none',
          },
          '&:before': {
            display: 'none',
          },
          '&$expanded': {
            margin: 'auto',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: ColorTableRowHead,
          borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
        content: {
          alignItems: 'center',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: '16px',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'inherit',
        elevation: 0,
      },
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'outlined',
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiChip-avatarColorPrimary': {
            backgroundColor: theme.palette.primary.main,
          },
          '& .MuiChip-avatarColorSecondary': {
            backgroundColor: theme.palette.secondary.main,
          },
          '& .MuiChip-avatarColorWarning': {
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.warning.contrastText,
          },
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${ ColorBorder2 }`,
          backgroundColor: ColorBackground,
          '&>.MuiButton-root': {
            minWidth: '100px',
          },
        },
        spacing: {
          padding: '16px 24px 16px 24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
          paddingTop: '16px !important',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${ ColorBorder2 }`,
          padding: '16px 24px',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          userSelect: 'none',
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        inputProps: {
          // Ignore 1Password
          'data-1p-ignore': 'true',
          // Ignore LastPass
          'data-lpignore': 'true',
        },
      },
      styleOverrides: {
        root: {
          '&$disabled': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          zIndex: 0,
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: ColorSupport,
          textDecoration: 'none',
          '&.MuiTypography-colorPrimary': {
            color: ColorSupport,
          },
          '& .MuiTypography-colorPrimary': {
            color: ColorSupport,
          },
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: 'rgba(64, 184, 124, 0.2)',
          },
          '&$selected:hover': {
            backgroundColor: 'rgba(64, 184, 124, 0.1)',
          },
        },
        button: {
          '&:hover': {
            backgroundColor: 'rgba(64, 184, 124, 0.1)',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '24px',
          // marginRight: '16px',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        sticky: {
          backgroundColor: ColorWhite,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          whiteSpace: 'initial',
        },
        dense: {
          color: ColorBlack,
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        select: {
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'initial',
          flexWrap: 'wrap',
          '&>.MuiSvgIcon-fontSizeSmall': {
            fontSize: '1rem',
          },
          // '&.MuiSelect-multiple': {
          //   padding: '6px 32px 6px 6px',
          //   '&>span, &>div': {
          //     minHeight: '28px',
          //   },
          // },
          '& .MuiChip-root': {
            fontSize: '1rem',
            height: '23px',
            borderRadius: 0,
            borderTop: 0,
            borderBottom: 0,
            borderLeft: 0,
            '& .MuiChip-label': {
              padding: '0 4px',
            },
            '&:first-of-type': {
              '& .MuiChip-label': {
                paddingLeft: 0,
              },
            },
            '&:last-child': {
              borderRight: 0,
              '& .MuiChip-label': {
                paddingRight: 0,
              },
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          borderRadius: '8px',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
        scrollButtons: 'auto',
        textColor: 'primary',
        variant: 'scrollable',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: LocalStorageService.load('FONT_FAMILY') ?? '"Roboto", "Helvetica", "Arial", sans-serif',
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: '20px',
        },
        head: {
          backgroundColor: ColorTableRowHead,
          fontWeight: 400,
        },
        sizeSmall: {
          '&.MuiTableCell-paddingCheckbox': {
            width: '20px',
            paddingRight: '16px',
          },
        },
        stickyHeader: {
          backgroundColor: ColorTableRowHead,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&$selected': {
            backgroundColor: ColorTableRowSelected,
          },
          '&.MuiTableRow-hover:hover': {
            backgroundColor: 'initial',
            '& > .MuiTableCell-root': {
              backgroundColor: ColorTableRowSelected,
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        variant: 'outlined',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.875rem',
          '&>.MuiLink-root.MuiTypography-colorPrimary': {
            color: ColorCaution,
          },
        },
      },
    },
  },
});
