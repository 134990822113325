import { apolloInMemoryCache } from '../services/graphqlService';
import { LocalStorageService, SessionStorageService } from '../services/storageService';

export interface SignInStatusProps {
  signInStatus: { status: SignInStatus, reason?: string; }
}

export enum SignInStatus {
  SIGNED_OUT = 'SIGNED_OUT',
  SIGNED_IN = 'SIGNED_IN',
}

export const signInStatusOnChange = (value: SignInStatusProps['signInStatus']): void => {
  if (value.status === SignInStatus.SIGNED_OUT) {
    LocalStorageService.clear();
    SessionStorageService.clear();
    document.cookie = '';
    apolloInMemoryCache.reset().then();
  }
};
