interface ConfigurationCommon {
  build_number: string;
  title: string;
}

interface ConfigurationByEnvironment {
  isProduction: boolean;
  environmentName: string;
  backendUrlBase: string;
  frontendUrlBase: string;
  websocketUrlBase: string;
  privateBucketUrlBase: string;
  jwtPublicKey: string;
  googleOAuthClientId: string;
}

// noinspection SpellCheckingInspection
const ConfigurationsByEnvironment: { [environment: string]: ConfigurationByEnvironment } = {
  prod: {
    isProduction: true,
    environmentName: 'prod',
    backendUrlBase: 'https://api.med.anymindgroup.com',
    frontendUrlBase: 'https://med.anymindgroup.com',
    websocketUrlBase: 'wss://api.med.anymindgroup.com',
    privateBucketUrlBase: 'https://private.med.anymindgroup.com',
    jwtPublicKey: `-----BEGIN PUBLIC KEY-----
MHYwEAYHKoZIzj0CAQYFK4EEACIDYgAEhyHUZh1JDEknew/gr1c5C/dUwVw/JphG
Kd9LNQvyGSyHx8L6UFUBGSszvcyZ3k5Hp7f+sFjCB5zCbWwVxa1MZCGXEqnphwK1
uRMsBTfCAbEV0sScT73ITAgO15K0yhrn
-----END PUBLIC KEY-----`,
    googleOAuthClientId: '341377457049-oh3ik5j50ev8hnor8jbf98ulpjsdhevd.apps.googleusercontent.com',
  },
  dev: {
    isProduction: false,
    environmentName: 'dev',
    backendUrlBase: 'https://api.dev.med.anymindgroup.com',
    frontendUrlBase: 'https://dev.med.anymindgroup.com',
    websocketUrlBase: 'wss://api.dev.med.anymindgroup.com',
    privateBucketUrlBase: 'https://private.dev.med.anymindgroup.com',
    jwtPublicKey: `-----BEGIN PUBLIC KEY-----
MHYwEAYHKoZIzj0CAQYFK4EEACIDYgAEqxQCtuhjvzOlJa0ESC+baN71Y0Yaw005
RTPknCqOBxIM+EG032Ta5iwOHOwKLeOiAs6g1OyUw+I+q735VZvT5G93hZOhH4dk
gDOKEI/4v3BGxkYfiOn240X7WHY8O+Fq
-----END PUBLIC KEY-----`,
    googleOAuthClientId: '341377457049-fhebcj35hpbh5grqgn300mpsl6qbuqtj.apps.googleusercontent.com',
  },
  local: {
    isProduction: false,
    environmentName: 'local',
    backendUrlBase: 'http://localhost:8086',
    frontendUrlBase: 'http://localhost:3000',
    websocketUrlBase: 'ws://localhost:8086',
    privateBucketUrlBase: '',
    jwtPublicKey: `-----BEGIN PUBLIC KEY-----
MHYwEAYHKoZIzj0CAQYFK4EEACIDYgAEqxQCtuhjvzOlJa0ESC+baN71Y0Yaw005
RTPknCqOBxIM+EG032Ta5iwOHOwKLeOiAs6g1OyUw+I+q735VZvT5G93hZOhH4dk
gDOKEI/4v3BGxkYfiOn240X7WHY8O+Fq
-----END PUBLIC KEY-----`,
    googleOAuthClientId: '341377457049-qj2ualkftl94pdduvqd9m0nei0djdh5q.apps.googleusercontent.com',
  },
};

const ConfigurationsCommon: ConfigurationCommon = {
  build_number: process.env.REACT_APP_BUILD_NUMBER ?? '(unknown)',
  title: 'AnyMind Master Employee DB',
};

export const Configuration: ConfigurationCommon & ConfigurationByEnvironment = {
  ...ConfigurationsCommon,
  ...ConfigurationsByEnvironment[process.env.REACT_APP_ENVIRONMENT ?? 'local'],
};
