/* tslint:disable */
/* eslint-disable */
/**
 * anymind-employee-backend-ecs-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: ogawa@anymindgroup.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const CalendarEventSendUpdates = {
  All: 'all',
  ExternalOnly: 'externalOnly',
  None: 'none',
} as const;

export type CalendarEventSendUpdates = (typeof CalendarEventSendUpdates)[keyof typeof CalendarEventSendUpdates];

/**
 *
 * @export
 * @enum {string}
 */

export const CalendarEventVisibility = {
  Default: 'default',
  Public: 'public',
  Private: 'private',
} as const;

export type CalendarEventVisibility = (typeof CalendarEventVisibility)[keyof typeof CalendarEventVisibility];

/**
 *
 * @export
 * @interface CommonResponseFailed
 */
export interface CommonResponseFailed {
  /**
   *
   * @type {boolean}
   * @memberof CommonResponseFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof CommonResponseFailed
   */
  status: number;
}
/**
 *
 * @export
 * @interface DomainCheckRequestBody
 */
export interface DomainCheckRequestBody {
  /**
   *
   * @type {string}
   * @memberof DomainCheckRequestBody
   */
  emailAddress: string;
  /**
   *
   * @type {boolean}
   * @memberof DomainCheckRequestBody
   */
  forcePin?: boolean;
}
/**
 *
 * @export
 * @interface DomainCheckResponseBody
 */
export interface DomainCheckResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof DomainCheckResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof DomainCheckResponseBody
   */
  status: number;
  /**
   *
   * @type {boolean}
   * @memberof DomainCheckResponseBody
   */
  isSSO: boolean;
  /**
   *
   * @type {string}
   * @memberof DomainCheckResponseBody
   */
  pinRef?: string;
  /**
   *
   * @type {string}
   * @memberof DomainCheckResponseBody
   */
  pinCreated?: string;
  /**
   *
   * @type {string}
   * @memberof DomainCheckResponseBody
   */
  message?: string;
  /**
   *
   * @type {Array<any>}
   * @memberof DomainCheckResponseBody
   */
  error?: Array<any>;
}
/**
 *
 * @export
 * @interface DomainCheckResponseBodyFailed
 */
export interface DomainCheckResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof DomainCheckResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof DomainCheckResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof DomainCheckResponseBodyFailed
   */
  message?: string;
  /**
   *
   * @type {Array<any>}
   * @memberof DomainCheckResponseBodyFailed
   */
  error?: Array<any>;
}
/**
 *
 * @export
 * @interface DomainCheckResponseBodySuccess
 */
export interface DomainCheckResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof DomainCheckResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof DomainCheckResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {boolean}
   * @memberof DomainCheckResponseBodySuccess
   */
  isSSO: boolean;
  /**
   *
   * @type {string}
   * @memberof DomainCheckResponseBodySuccess
   */
  pinRef?: string;
  /**
   *
   * @type {string}
   * @memberof DomainCheckResponseBodySuccess
   */
  pinCreated?: string;
}
/**
 *
 * @export
 * @interface EmployeeFileDownloadResponseBody
 */
export interface EmployeeFileDownloadResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeFileDownloadResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof EmployeeFileDownloadResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeFileDownloadResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface EmployeeFileDownloadResponseBodyFailed
 */
export interface EmployeeFileDownloadResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeFileDownloadResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof EmployeeFileDownloadResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeFileDownloadResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface EmployeeFileDownloadResponseBodySuccess
 */
export interface EmployeeFileDownloadResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeFileDownloadResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof EmployeeFileDownloadResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeFileDownloadResponseBodySuccess
   */
  message: string;
}
/**
 *
 * @export
 * @interface EmployeeFileUploadResponseBody
 */
export interface EmployeeFileUploadResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeFileUploadResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof EmployeeFileUploadResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeFileUploadResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface EmployeeFileUploadResponseBodyFailed
 */
export interface EmployeeFileUploadResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeFileUploadResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof EmployeeFileUploadResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeFileUploadResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface EmployeeFileUploadResponseBodySuccess
 */
export interface EmployeeFileUploadResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof EmployeeFileUploadResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof EmployeeFileUploadResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof EmployeeFileUploadResponseBodySuccess
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExportEmployeeRequestBody
 */
export interface ExportEmployeeRequestBody {
  /**
   *
   * @type {any}
   * @memberof ExportEmployeeRequestBody
   */
  variables: any;
  /**
   *
   * @type {any}
   * @memberof ExportEmployeeRequestBody
   */
  meta?: any;
}
/**
 *
 * @export
 * @interface ExportEmployeeResponseBody
 */
export interface ExportEmployeeResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof ExportEmployeeResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExportEmployeeResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ExportEmployeeResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExportEmployeeResponseBodyFailed
 */
export interface ExportEmployeeResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof ExportEmployeeResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExportEmployeeResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ExportEmployeeResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExportEmployeeResponseBodySuccess
 */
export interface ExportEmployeeResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof ExportEmployeeResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExportEmployeeResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof ExportEmployeeResponseBodySuccess
   */
  message: string | null;
}
/**
 *
 * @export
 * @interface ExportPerformanceReviewResultRequestBody
 */
export interface ExportPerformanceReviewResultRequestBody {
  /**
   *
   * @type {ExportPerformanceReviewResultRequestBodyGoogle}
   * @memberof ExportPerformanceReviewResultRequestBody
   */
  google: ExportPerformanceReviewResultRequestBodyGoogle;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultRequestBody
   */
  timezoneOffset: number;
  /**
   *
   * @type {ExportPerformanceReviewResultRequestBodyArguments}
   * @memberof ExportPerformanceReviewResultRequestBody
   */
  arguments: ExportPerformanceReviewResultRequestBodyArguments;
}
/**
 *
 * @export
 * @interface ExportPerformanceReviewResultRequestBodyArguments
 */
export interface ExportPerformanceReviewResultRequestBodyArguments {
  /**
   *
   * @type {boolean}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  hideUnfilled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  hideResigned: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  sortDesc: boolean | null;
  /**
   *
   * @type {SortKey}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  sortKey: SortKey | null;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  departmentId: number | null;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  divisionId: number | null;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  businessUnitId: number | null;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  companyId: number | null;
  /**
   *
   * @type {string}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  countryCodeAlpha2: string | null;
  /**
   *
   * @type {string}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  quarter: string;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultRequestBodyArguments
   */
  year: number;
}

/**
 *
 * @export
 * @interface ExportPerformanceReviewResultRequestBodyGoogle
 */
export interface ExportPerformanceReviewResultRequestBodyGoogle {
  /**
   *
   * @type {string}
   * @memberof ExportPerformanceReviewResultRequestBodyGoogle
   */
  driveParentId: string;
  /**
   *
   * @type {string}
   * @memberof ExportPerformanceReviewResultRequestBodyGoogle
   */
  accessToken: string;
}
/**
 *
 * @export
 * @interface ExportPerformanceReviewResultResponseBody
 */
export interface ExportPerformanceReviewResultResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof ExportPerformanceReviewResultResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ExportPerformanceReviewResultResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExportPerformanceReviewResultResponseBodyFailed
 */
export interface ExportPerformanceReviewResultResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof ExportPerformanceReviewResultResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ExportPerformanceReviewResultResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExportPerformanceReviewResultResponseBodySuccess
 */
export interface ExportPerformanceReviewResultResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof ExportPerformanceReviewResultResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExportPerformanceReviewResultResponseBodySuccess
   */
  status?: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ExternalAccountEmployeeType = {
  GoogleWorkspace: 'GOOGLE_WORKSPACE',
  Hubspot: 'HUBSPOT',
  NetsuiteEmployee: 'NETSUITE_EMPLOYEE',
  Slack: 'SLACK',
} as const;

export type ExternalAccountEmployeeType = (typeof ExternalAccountEmployeeType)[keyof typeof ExternalAccountEmployeeType];

/**
 *
 * @export
 * @interface ExternalAccountRequestBody
 */
export interface ExternalAccountRequestBody {
  /**
   *
   * @type {Array<ExternalAccountRequestBodyValue>}
   * @memberof ExternalAccountRequestBody
   */
  values: Array<ExternalAccountRequestBodyValue>;
}
/**
 *
 * @export
 * @interface ExternalAccountRequestBodyValue
 */
export interface ExternalAccountRequestBodyValue {
  /**
   *
   * @type {ExternalAccountEmployeeType}
   * @memberof ExternalAccountRequestBodyValue
   */
  externalAccountType: ExternalAccountEmployeeType;
  /**
   *
   * @type {string}
   * @memberof ExternalAccountRequestBodyValue
   */
  employeeId: string;
}

/**
 *
 * @export
 * @interface ExternalAccountResponseBody
 */
export interface ExternalAccountResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof ExternalAccountResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExternalAccountResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ExternalAccountResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExternalAccountResponseBodyFailed
 */
export interface ExternalAccountResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof ExternalAccountResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExternalAccountResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ExternalAccountResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ExternalAccountResponseBodySuccess
 */
export interface ExternalAccountResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof ExternalAccountResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ExternalAccountResponseBodySuccess
   */
  status?: number;
}
/**
 *
 * @export
 * @interface GoogleAccountListRequestBody
 */
export interface GoogleAccountListRequestBody {
  /**
   *
   * @type {string}
   * @memberof GoogleAccountListRequestBody
   */
  query?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GoogleAccountListRequestBody
   */
  fields?: Array<string>;
}
/**
 *
 * @export
 * @interface GoogleAccountListResponseBody
 */
export interface GoogleAccountListResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof GoogleAccountListResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleAccountListResponseBody
   */
  status: number;
  /**
   *
   * @type {Array<any>}
   * @memberof GoogleAccountListResponseBody
   */
  users: Array<any>;
  /**
   *
   * @type {string}
   * @memberof GoogleAccountListResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleAccountListResponseBodyFailed
 */
export interface GoogleAccountListResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof GoogleAccountListResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleAccountListResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleAccountListResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleAccountListResponseBodySuccess
 */
export interface GoogleAccountListResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof GoogleAccountListResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleAccountListResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof GoogleAccountListResponseBodySuccess
   */
  users: Array<any>;
}
/**
 *
 * @export
 * @interface GoogleCalendarCreateRequestBody
 */
export interface GoogleCalendarCreateRequestBody {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBody
   */
  calendarId?: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBody
   */
  title: string;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarCreateRequestBody
   */
  isAllDay: boolean;
  /**
   *
   * @type {GoogleCalendarCreateRequestBodyDates}
   * @memberof GoogleCalendarCreateRequestBody
   */
  dates: GoogleCalendarCreateRequestBodyDates;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBody
   */
  description: string;
  /**
   *
   * @type {Array<GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInner>}
   * @memberof GoogleCalendarCreateRequestBody
   */
  attendeeEmailAddresses: Array<GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInner>;
  /**
   *
   * @type {CalendarEventSendUpdates}
   * @memberof GoogleCalendarCreateRequestBody
   */
  sendUpdates: CalendarEventSendUpdates;
  /**
   *
   * @type {CalendarEventVisibility}
   * @memberof GoogleCalendarCreateRequestBody
   */
  visibility: CalendarEventVisibility;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof GoogleCalendarCreateRequestBody
   */
  sharedExtendedProperties?: { [key: string]: string };
}

/**
 *
 * @export
 * @interface GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInner
 */
export interface GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInner {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInner
   */
  optional?: boolean;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInner
   */
  email: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInnerAnyOf
 */
export interface GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInnerAnyOf {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInnerAnyOf
   */
  optional?: boolean;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBodyAttendeeEmailAddressesInnerAnyOf
   */
  email: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarCreateRequestBodyDates
 */
export interface GoogleCalendarCreateRequestBodyDates {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBodyDates
   */
  end: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateRequestBodyDates
   */
  start: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarCreateResponseBody
 */
export interface GoogleCalendarCreateResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarCreateResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarCreateResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateResponseBody
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateResponseBody
   */
  htmlLink: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarCreateResponseBodyFailed
 */
export interface GoogleCalendarCreateResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarCreateResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarCreateResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarCreateResponseBodySuccess
 */
export interface GoogleCalendarCreateResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarCreateResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarCreateResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateResponseBodySuccess
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarCreateResponseBodySuccess
   */
  htmlLink: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarDeleteRequestBody
 */
export interface GoogleCalendarDeleteRequestBody {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarDeleteRequestBody
   */
  calendarId?: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarDeleteRequestBody
   */
  eventId: string;
  /**
   *
   * @type {CalendarEventSendUpdates}
   * @memberof GoogleCalendarDeleteRequestBody
   */
  sendUpdates: CalendarEventSendUpdates;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarDeleteRequestBody
   */
  raiseErrorWhenNotFound: boolean;
}

/**
 *
 * @export
 * @interface GoogleCalendarDeleteResponseBody
 */
export interface GoogleCalendarDeleteResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarDeleteResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarDeleteResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarDeleteResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarDeleteResponseBodyFailed
 */
export interface GoogleCalendarDeleteResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarDeleteResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarDeleteResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarDeleteResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarDeleteResponseBodySuccess
 */
export interface GoogleCalendarDeleteResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarDeleteResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarDeleteResponseBodySuccess
   */
  status?: number;
}
/**
 *
 * @export
 * @interface GoogleCalendarEventProperty
 */
export interface GoogleCalendarEventProperty {
  [key: string]: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarListRequestBody
 */
export interface GoogleCalendarListRequestBody {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListRequestBody
   */
  calendarId?: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListRequestBody
   */
  timeMin: string;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListRequestBody
   */
  timeMax: string;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListRequestBody
   */
  eventsForMED?: boolean;
  /**
   *
   * @type {GoogleCalendarListRequestBodyExtendedProperties}
   * @memberof GoogleCalendarListRequestBody
   */
  extendedProperties?: GoogleCalendarListRequestBodyExtendedProperties;
}
/**
 *
 * @export
 * @interface GoogleCalendarListRequestBodyExtendedProperties
 */
export interface GoogleCalendarListRequestBodyExtendedProperties {
  /**
   *
   * @type {GoogleCalendarEventProperty}
   * @memberof GoogleCalendarListRequestBodyExtendedProperties
   */
  shared?: GoogleCalendarEventProperty;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBody
 */
export interface GoogleCalendarListResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBody
   */
  status: number;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessCalendar}
   * @memberof GoogleCalendarListResponseBody
   */
  calendar: GoogleCalendarListResponseBodySuccessCalendar;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessColor}
   * @memberof GoogleCalendarListResponseBody
   */
  colors: GoogleCalendarListResponseBodySuccessColor;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessResources}
   * @memberof GoogleCalendarListResponseBody
   */
  resources: GoogleCalendarListResponseBodySuccessResources;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEvents}
   * @memberof GoogleCalendarListResponseBody
   */
  events: GoogleCalendarListResponseBodySuccessEvents;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodyFailed
 */
export interface GoogleCalendarListResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccess
 */
export interface GoogleCalendarListResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessCalendar}
   * @memberof GoogleCalendarListResponseBodySuccess
   */
  calendar: GoogleCalendarListResponseBodySuccessCalendar;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessColor}
   * @memberof GoogleCalendarListResponseBodySuccess
   */
  colors: GoogleCalendarListResponseBodySuccessColor;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessResources}
   * @memberof GoogleCalendarListResponseBodySuccess
   */
  resources: GoogleCalendarListResponseBodySuccessResources;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEvents}
   * @memberof GoogleCalendarListResponseBodySuccess
   */
  events: GoogleCalendarListResponseBodySuccessEvents;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessCalendar
 */
export interface GoogleCalendarListResponseBodySuccessCalendar {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessCalendar
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessCalendar
   */
  backgroundColor: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessCalendar
   */
  foregroundColor: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessColor
 */
export interface GoogleCalendarListResponseBodySuccessColor {
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessColorDefinition}
   * @memberof GoogleCalendarListResponseBodySuccessColor
   */
  calendar: GoogleCalendarListResponseBodySuccessColorDefinition;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessColorDefinition}
   * @memberof GoogleCalendarListResponseBodySuccessColor
   */
  event: GoogleCalendarListResponseBodySuccessColorDefinition;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessColorDefinition
 */
export interface GoogleCalendarListResponseBodySuccessColorDefinition {
  [key: string]: GoogleCalendarListResponseBodySuccessColorDefinitionValue;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessColorDefinitionValue
 */
export interface GoogleCalendarListResponseBodySuccessColorDefinitionValue {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessColorDefinitionValue
   */
  foreground: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessColorDefinitionValue
   */
  background: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEvent
 */
export interface GoogleCalendarListResponseBodySuccessEvent {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  id: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  status: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  htmlLink: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  summary: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  description: string | null;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventDateTime}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  start: GoogleCalendarListResponseBodySuccessEventDateTime | null;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventDateTime}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  end: GoogleCalendarListResponseBodySuccessEventDateTime | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  location: string | null;
  /**
   *
   * @type {Array<GoogleCalendarListResponseBodySuccessEventAttendeesInner>}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  attendees: Array<GoogleCalendarListResponseBodySuccessEventAttendeesInner>;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventCreator}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  creator: GoogleCalendarListResponseBodySuccessEventCreator;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  color: string | null;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventConferenceData}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  conferenceData: GoogleCalendarListResponseBodySuccessEventConferenceData | null;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventExtendedProperties}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  extendedProperties: GoogleCalendarListResponseBodySuccessEventExtendedProperties | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEvent
   */
  eventType: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventAttendeesInner
 */
export interface GoogleCalendarListResponseBodySuccessEventAttendeesInner {
  /**
   *
   * @type {ResponseStatus}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  responseStatus: ResponseStatus | null;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  isSelf: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  isResource: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  isOrganizer: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  isOptional: boolean;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  displayName: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  comment: string | null;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBodySuccessEventAttendeesInner
   */
  additionalGuests: number | null;
}

/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventConferenceData
 */
export interface GoogleCalendarListResponseBodySuccessEventConferenceData {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventConferenceData
   */
  entryPointVideoUri: string | null;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution}
   * @memberof GoogleCalendarListResponseBodySuccessEventConferenceData
   */
  conferenceSolution: GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution
 */
export interface GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution
   */
  iconUri: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution
   */
  name: string | null;
  /**
   *
   * @type {GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolutionKey}
   * @memberof GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolution
   */
  key: GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolutionKey | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolutionKey
 */
export interface GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolutionKey {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventConferenceDataConferenceSolutionKey
   */
  type: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventCreator
 */
export interface GoogleCalendarListResponseBodySuccessEventCreator {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventCreator
   */
  displayName: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventCreator
   */
  email: string | null;
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccessEventCreator
   */
  isSelf: boolean;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventDateTime
 */
export interface GoogleCalendarListResponseBodySuccessEventDateTime {
  /**
   *
   * @type {boolean}
   * @memberof GoogleCalendarListResponseBodySuccessEventDateTime
   */
  isAllDay: boolean;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventDateTime
   */
  date: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventDateTime
   */
  time: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessEventDateTime
   */
  timeZone: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEventExtendedProperties
 */
export interface GoogleCalendarListResponseBodySuccessEventExtendedProperties {
  /**
   *
   * @type {GoogleCalendarEventProperty}
   * @memberof GoogleCalendarListResponseBodySuccessEventExtendedProperties
   */
  shared: GoogleCalendarEventProperty | null;
  /**
   *
   * @type {GoogleCalendarEventProperty}
   * @memberof GoogleCalendarListResponseBodySuccessEventExtendedProperties
   */
  private: GoogleCalendarEventProperty | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessEvents
 */
export interface GoogleCalendarListResponseBodySuccessEvents {
  /**
   *
   * @type {Array<GoogleCalendarListResponseBodySuccessEvent>}
   * @memberof GoogleCalendarListResponseBodySuccessEvents
   */
  items: Array<GoogleCalendarListResponseBodySuccessEvent>;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBodySuccessEvents
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessResource
 */
export interface GoogleCalendarListResponseBodySuccessResource {
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessResource
   */
  generatedResourceName: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessResource
   */
  resourceType: string | null;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBodySuccessResource
   */
  capacity: number | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessResource
   */
  buildingId: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessResource
   */
  floorName: string | null;
  /**
   *
   * @type {string}
   * @memberof GoogleCalendarListResponseBodySuccessResource
   */
  resourceCategory: string | null;
}
/**
 *
 * @export
 * @interface GoogleCalendarListResponseBodySuccessResources
 */
export interface GoogleCalendarListResponseBodySuccessResources {
  /**
   *
   * @type {Array<GoogleCalendarListResponseBodySuccessResource>}
   * @memberof GoogleCalendarListResponseBodySuccessResources
   */
  items: Array<GoogleCalendarListResponseBodySuccessResource>;
  /**
   *
   * @type {number}
   * @memberof GoogleCalendarListResponseBodySuccessResources
   */
  totalCount: number;
}
/**
 *
 * @export
 * @interface GoogleOrgUnitListResponseBody
 */
export interface GoogleOrgUnitListResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof GoogleOrgUnitListResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleOrgUnitListResponseBody
   */
  status: number;
  /**
   *
   * @type {Array<any>}
   * @memberof GoogleOrgUnitListResponseBody
   */
  orgUnits: Array<any>;
  /**
   *
   * @type {string}
   * @memberof GoogleOrgUnitListResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleOrgUnitListResponseBodyFailed
 */
export interface GoogleOrgUnitListResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof GoogleOrgUnitListResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleOrgUnitListResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof GoogleOrgUnitListResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface GoogleOrgUnitListResponseBodySuccess
 */
export interface GoogleOrgUnitListResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof GoogleOrgUnitListResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof GoogleOrgUnitListResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof GoogleOrgUnitListResponseBodySuccess
   */
  orgUnits: Array<any>;
}
/**
 *
 * @export
 * @interface ImportEmployeeRequestBody
 */
export interface ImportEmployeeRequestBody {
  /**
   *
   * @type {ImportEmployeeRequestBodyGoogle}
   * @memberof ImportEmployeeRequestBody
   */
  google: ImportEmployeeRequestBodyGoogle;
  /**
   *
   * @type {number}
   * @memberof ImportEmployeeRequestBody
   */
  timezoneOffset: number;
}
/**
 *
 * @export
 * @interface ImportEmployeeRequestBodyGoogle
 */
export interface ImportEmployeeRequestBodyGoogle {
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeRequestBodyGoogle
   */
  driveFileUrl: string;
  /**
   *
   * @type {ImportEmployeeRequestBodyGoogleCredential}
   * @memberof ImportEmployeeRequestBodyGoogle
   */
  credential: ImportEmployeeRequestBodyGoogleCredential;
}
/**
 *
 * @export
 * @interface ImportEmployeeRequestBodyGoogleCredential
 */
export interface ImportEmployeeRequestBodyGoogleCredential {
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeRequestBodyGoogleCredential
   */
  scope: string;
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeRequestBodyGoogleCredential
   */
  idToken: string;
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeRequestBodyGoogleCredential
   */
  tokenType: string;
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeRequestBodyGoogleCredential
   */
  accessToken: string;
  /**
   *
   * @type {number}
   * @memberof ImportEmployeeRequestBodyGoogleCredential
   */
  expiryDate: number;
}
/**
 *
 * @export
 * @interface ImportEmployeeResponseBodyFailed
 */
export interface ImportEmployeeResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof ImportEmployeeResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ImportEmployeeResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ImportEmployeeResponseBodySuccess
 */
export interface ImportEmployeeResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof ImportEmployeeResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ImportEmployeeResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof ImportEmployeeResponseBodySuccess
   */
  message: string | null;
}
/**
 *
 * @export
 * @interface InviteUserRequestBody
 */
export interface InviteUserRequestBody {
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  ipAddress?: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  userAgent?: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  email_address: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  remark?: string;
  /**
   *
   * @type {number}
   * @memberof InviteUserRequestBody
   */
  timezone_offset?: number;
  /**
   *
   * @type {string}
   * @memberof InviteUserRequestBody
   */
  from?: string;
  /**
   *
   * @type {boolean}
   * @memberof InviteUserRequestBody
   */
  skip_email?: boolean;
}
/**
 *
 * @export
 * @interface InviteUserResponseBody
 */
export interface InviteUserResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof InviteUserResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof InviteUserResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof InviteUserResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface InviteUserResponseBodyFailed
 */
export interface InviteUserResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof InviteUserResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof InviteUserResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof InviteUserResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface InviteUserResponseBodySuccess
 */
export interface InviteUserResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof InviteUserResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof InviteUserResponseBodySuccess
   */
  status?: number;
}
/**
 *
 * @export
 * @interface ProbationReviewRequestBody
 */
export interface ProbationReviewRequestBody {
  /**
   *
   * @type {string}
   * @memberof ProbationReviewRequestBody
   */
  employeeId: string;
  /**
   *
   * @type {string}
   * @memberof ProbationReviewRequestBody
   */
  reviewDatePlanned: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ProbationReviewRequestBody
   */
  reviewerEmployeeIdList?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ProbationReviewRequestBody
   */
  isFirst?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ProbationReviewRequestBody
   */
  isFinalOrExtended?: boolean;
}
/**
 *
 * @export
 * @interface ProbationReviewResponseBody
 */
export interface ProbationReviewResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof ProbationReviewResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ProbationReviewResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ProbationReviewResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface ProbationReviewResponseBodyFailed
 */
export interface ProbationReviewResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof ProbationReviewResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ProbationReviewResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ProbationReviewResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface ProbationReviewResponseBodySuccess
 */
export interface ProbationReviewResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof ProbationReviewResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ProbationReviewResponseBodySuccess
   */
  status?: number;
}
/**
 *
 * @export
 * @interface ResponseBody
 */
export interface ResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof ResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof ResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof ResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface ResponseBodySucceededResult
 */
export interface ResponseBodySucceededResult {
  /**
   *
   * @type {Array<boolean>}
   * @memberof ResponseBodySucceededResult
   */
  isCorrect: Array<boolean>;
  /**
   *
   * @type {string}
   * @memberof ResponseBodySucceededResult
   */
  original: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ResponseBodySucceededResult
   */
  words: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof ResponseBodySucceededResult
   */
  corrections: Array<Array<string>>;
  /**
   *
   * @type {string}
   * @memberof ResponseBodySucceededResult
   */
  id: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ResponseStatus = {
  NeedsAction: 'needsAction',
  Accepted: 'accepted',
  Tentative: 'tentative',
  Declined: 'declined',
} as const;

export type ResponseStatus = (typeof ResponseStatus)[keyof typeof ResponseStatus];

/**
 *
 * @export
 * @interface SSOGenerateUrlResponseBody
 */
export interface SSOGenerateUrlResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof SSOGenerateUrlResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SSOGenerateUrlResponseBody
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof SSOGenerateUrlResponseBody
   */
  url: string;
}
/**
 *
 * @export
 * @interface SSOGenerateUrlResponseBodyFailed
 */
export interface SSOGenerateUrlResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof SSOGenerateUrlResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SSOGenerateUrlResponseBodyFailed
   */
  status: number;
}
/**
 *
 * @export
 * @interface SSOGenerateUrlResponseBodySuccess
 */
export interface SSOGenerateUrlResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof SSOGenerateUrlResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SSOGenerateUrlResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {string}
   * @memberof SSOGenerateUrlResponseBodySuccess
   */
  url: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const SortKey = {
  Name: 'NAME',
  BusinessUnit: 'BUSINESS_UNIT',
  Division: 'DIVISION',
  Department: 'DEPARTMENT',
  PrimaryManager: 'PRIMARY_MANAGER',
  EmployeeLevel: 'EMPLOYEE_LEVEL',
  ScoreOverall: 'SCORE_OVERALL',
  ScoreValue: 'SCORE_VALUE',
  ScoreCompetency: 'SCORE_COMPETENCY',
  ScoreTargetTotal: 'SCORE_TARGET_TOTAL',
  ScoreKpi: 'SCORE_KPI',
  ScoreQualitative: 'SCORE_QUALITATIVE',
} as const;

export type SortKey = (typeof SortKey)[keyof typeof SortKey];

/**
 *
 * @export
 * @interface SpellCheckExistingRequestBody
 */
export interface SpellCheckExistingRequestBody {
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingRequestBody
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingRequestBody
   */
  targetName: string;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingRequestBody
   */
  responseIdName: string;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingRequestBody
   */
  idType?: SpellCheckExistingRequestBodyIdTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof SpellCheckExistingRequestBody
   */
  filters?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingRequestBody
   */
  language?: string;
}

export const SpellCheckExistingRequestBodyIdTypeEnum = {
  BigInt: 'BigInt',
  String: 'String',
} as const;

export type SpellCheckExistingRequestBodyIdTypeEnum = (typeof SpellCheckExistingRequestBodyIdTypeEnum)[keyof typeof SpellCheckExistingRequestBodyIdTypeEnum];

/**
 *
 * @export
 * @interface SpellCheckExistingResponseBody
 */
export interface SpellCheckExistingResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof SpellCheckExistingResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SpellCheckExistingResponseBody
   */
  status: number;
  /**
   *
   * @type {Array<ResponseBodySucceededResult>}
   * @memberof SpellCheckExistingResponseBody
   */
  result: Array<ResponseBodySucceededResult>;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface SpellCheckExistingResponseBodyFailed
 */
export interface SpellCheckExistingResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof SpellCheckExistingResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SpellCheckExistingResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface SpellCheckExistingResponseBodySucceed
 */
export interface SpellCheckExistingResponseBodySucceed {
  /**
   *
   * @type {boolean}
   * @memberof SpellCheckExistingResponseBodySucceed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SpellCheckExistingResponseBodySucceed
   */
  status?: number;
  /**
   *
   * @type {Array<ResponseBodySucceededResult>}
   * @memberof SpellCheckExistingResponseBodySucceed
   */
  result: Array<ResponseBodySucceededResult>;
}
/**
 *
 * @export
 * @interface SpellCheckExistingResponseBodySucceedResult
 */
export interface SpellCheckExistingResponseBodySucceedResult {
  /**
   *
   * @type {Array<boolean>}
   * @memberof SpellCheckExistingResponseBodySucceedResult
   */
  isCorrect: Array<boolean>;
  /**
   *
   * @type {string}
   * @memberof SpellCheckExistingResponseBodySucceedResult
   */
  original: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SpellCheckExistingResponseBodySucceedResult
   */
  words: Array<string>;
  /**
   *
   * @type {Array<Array<string>>}
   * @memberof SpellCheckExistingResponseBodySucceedResult
   */
  corrections: Array<Array<string>>;
}
/**
 *
 * @export
 * @interface SpellCheckWordRequestBody
 */
export interface SpellCheckWordRequestBody {
  /**
   *
   * @type {string}
   * @memberof SpellCheckWordRequestBody
   */
  word: string;
  /**
   *
   * @type {string}
   * @memberof SpellCheckWordRequestBody
   */
  language?: string;
}
/**
 *
 * @export
 * @interface SpellCheckWordResponseBody
 */
export interface SpellCheckWordResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof SpellCheckWordResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SpellCheckWordResponseBody
   */
  status: number;
  /**
   *
   * @type {SpellCheckExistingResponseBodySucceedResult}
   * @memberof SpellCheckWordResponseBody
   */
  result: SpellCheckExistingResponseBodySucceedResult;
  /**
   *
   * @type {string}
   * @memberof SpellCheckWordResponseBody
   */
  message: string;
}
/**
 *
 * @export
 * @interface SpellCheckWordResponseBodyFailed
 */
export interface SpellCheckWordResponseBodyFailed {
  /**
   *
   * @type {boolean}
   * @memberof SpellCheckWordResponseBodyFailed
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SpellCheckWordResponseBodyFailed
   */
  status: number;
  /**
   *
   * @type {string}
   * @memberof SpellCheckWordResponseBodyFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface SpellCheckWordResponseBodySuccess
 */
export interface SpellCheckWordResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof SpellCheckWordResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof SpellCheckWordResponseBodySuccess
   */
  status?: number;
  /**
   *
   * @type {SpellCheckExistingResponseBodySucceedResult}
   * @memberof SpellCheckWordResponseBodySuccess
   */
  result: SpellCheckExistingResponseBodySucceedResult;
}
/**
 *
 * @export
 * @interface UserAccountAccessHistoryRequestBody
 */
export interface UserAccountAccessHistoryRequestBody {
  /**
   *
   * @type {number}
   * @memberof UserAccountAccessHistoryRequestBody
   */
  timezone_offset: number;
  /**
   *
   * @type {string}
   * @memberof UserAccountAccessHistoryRequestBody
   */
  user_agent: string | null;
  /**
   *
   * @type {Array<string>}
   * @memberof UserAccountAccessHistoryRequestBody
   */
  languages: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof UserAccountAccessHistoryRequestBody
   */
  referrer: string | null;
}
/**
 *
 * @export
 * @interface UserAccountAccessHistoryResponseBody
 */
export interface UserAccountAccessHistoryResponseBody {
  /**
   *
   * @type {boolean}
   * @memberof UserAccountAccessHistoryResponseBody
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof UserAccountAccessHistoryResponseBody
   */
  status: number;
}
/**
 *
 * @export
 * @interface UserAccountAccessHistoryResponseBodySuccess
 */
export interface UserAccountAccessHistoryResponseBodySuccess {
  /**
   *
   * @type {boolean}
   * @memberof UserAccountAccessHistoryResponseBodySuccess
   */
  success: boolean;
  /**
   *
   * @type {number}
   * @memberof UserAccountAccessHistoryResponseBodySuccess
   */
  status?: number;
}
/**
 *
 * @export
 * @interface Version200Response
 */
export interface Version200Response {
  /**
   *
   * @type {string}
   * @memberof Version200Response
   */
  build_hash: string;
  /**
   *
   * @type {string}
   * @memberof Version200Response
   */
  build_number: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ExternalAccountRequestBody} externalAccountRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeExternalAccountProcessNewEmployee: async (externalAccountRequestBody: ExternalAccountRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'externalAccountRequestBody' is not null or undefined
      assertParamExists('employeeExternalAccountProcessNewEmployee', 'externalAccountRequestBody', externalAccountRequestBody);
      const localVarPath = `/employee/externalAccount/processNewEmployee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(externalAccountRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} employeeFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeFileDownload: async (employeeFileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'employeeFileId' is not null or undefined
      assertParamExists('employeeFileDownload', 'employeeFileId', employeeFileId);
      const localVarPath = `/employee/file/download/{employeeFileId}`.replace(`{${'employeeFileId'}}`, encodeURIComponent(String(employeeFileId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeFileUpload: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/employee/file/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ProbationReviewRequestBody} probationReviewRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeProbationReviewCreate: async (probationReviewRequestBody: ProbationReviewRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'probationReviewRequestBody' is not null or undefined
      assertParamExists('employeeProbationReviewCreate', 'probationReviewRequestBody', probationReviewRequestBody);
      const localVarPath = `/employee/probationReview/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(probationReviewRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} fileMode
     * @param {ExportEmployeeRequestBody} exportEmployeeRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportEmployee: async (fileMode: string, exportEmployeeRequestBody: ExportEmployeeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fileMode' is not null or undefined
      assertParamExists('exportEmployee', 'fileMode', fileMode);
      // verify required parameter 'exportEmployeeRequestBody' is not null or undefined
      assertParamExists('exportEmployee', 'exportEmployeeRequestBody', exportEmployeeRequestBody);
      const localVarPath = `/export/employee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      if (fileMode !== undefined) {
        localVarQueryParameter['fileMode'] = fileMode;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(exportEmployeeRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GoogleAccountListRequestBody} googleAccountListRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleAccountList: async (googleAccountListRequestBody: GoogleAccountListRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'googleAccountListRequestBody' is not null or undefined
      assertParamExists('externalAccountGoogleAccountList', 'googleAccountListRequestBody', googleAccountListRequestBody);
      const localVarPath = `/externalAccount/google/account/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(googleAccountListRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GoogleCalendarCreateRequestBody} googleCalendarCreateRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleCalendarCreate: async (googleCalendarCreateRequestBody: GoogleCalendarCreateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'googleCalendarCreateRequestBody' is not null or undefined
      assertParamExists('externalAccountGoogleCalendarCreate', 'googleCalendarCreateRequestBody', googleCalendarCreateRequestBody);
      const localVarPath = `/externalAccount/google/calendar/create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(googleCalendarCreateRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GoogleCalendarDeleteRequestBody} googleCalendarDeleteRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleCalendarDelete: async (googleCalendarDeleteRequestBody: GoogleCalendarDeleteRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'googleCalendarDeleteRequestBody' is not null or undefined
      assertParamExists('externalAccountGoogleCalendarDelete', 'googleCalendarDeleteRequestBody', googleCalendarDeleteRequestBody);
      const localVarPath = `/externalAccount/google/calendar/delete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(googleCalendarDeleteRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {GoogleCalendarListRequestBody} googleCalendarListRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleCalendarList: async (googleCalendarListRequestBody: GoogleCalendarListRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'googleCalendarListRequestBody' is not null or undefined
      assertParamExists('externalAccountGoogleCalendarList', 'googleCalendarListRequestBody', googleCalendarListRequestBody);
      const localVarPath = `/externalAccount/google/calendar/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(googleCalendarListRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleOrgUnitList: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('externalAccountGoogleOrgUnitList', 'body', body);
      const localVarPath = `/externalAccount/google/orgUnit/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} fileMode
     * @param {ImportEmployeeRequestBody} importEmployeeRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importEmployee: async (fileMode: string, importEmployeeRequestBody: ImportEmployeeRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fileMode' is not null or undefined
      assertParamExists('importEmployee', 'fileMode', fileMode);
      // verify required parameter 'importEmployeeRequestBody' is not null or undefined
      assertParamExists('importEmployee', 'importEmployeeRequestBody', importEmployeeRequestBody);
      const localVarPath = `/import/employee`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      if (fileMode !== undefined) {
        localVarQueryParameter['fileMode'] = fileMode;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(importEmployeeRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {ExportPerformanceReviewResultRequestBody} exportPerformanceReviewResultRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performanceReviewResult: async (exportPerformanceReviewResultRequestBody: ExportPerformanceReviewResultRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'exportPerformanceReviewResultRequestBody' is not null or undefined
      assertParamExists('performanceReviewResult', 'exportPerformanceReviewResultRequestBody', exportPerformanceReviewResultRequestBody);
      const localVarPath = `/export/performance-review/result`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(exportPerformanceReviewResultRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SpellCheckExistingRequestBody} spellCheckExistingRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spellCheckExisting: async (spellCheckExistingRequestBody: SpellCheckExistingRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spellCheckExistingRequestBody' is not null or undefined
      assertParamExists('spellCheckExisting', 'spellCheckExistingRequestBody', spellCheckExistingRequestBody);
      const localVarPath = `/spellCheck/existing`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(spellCheckExistingRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {SpellCheckWordRequestBody} spellCheckWordRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spellCheckWord: async (spellCheckWordRequestBody: SpellCheckWordRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'spellCheckWordRequestBody' is not null or undefined
      assertParamExists('spellCheckWord', 'spellCheckWordRequestBody', spellCheckWordRequestBody);
      const localVarPath = `/spellCheck/word`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(spellCheckWordRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {UserAccountAccessHistoryRequestBody} userAccountAccessHistoryRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAccountAccessHistory: async (userAccountAccessHistoryRequestBody: UserAccountAccessHistoryRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userAccountAccessHistoryRequestBody' is not null or undefined
      assertParamExists('userAccountAccessHistory', 'userAccountAccessHistoryRequestBody', userAccountAccessHistoryRequestBody);
      const localVarPath = `/userAccount/accessHistory`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication jwt required
      await setApiKeyToObject(localVarHeaderParameter, 'authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(userAccountAccessHistoryRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} emailAddress
     * @param {string} ref
     * @param {string} pin
     * @param {string} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthenticate: async (emailAddress: string, ref: string, pin: string, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'emailAddress' is not null or undefined
      assertParamExists('userAuthenticate', 'emailAddress', emailAddress);
      // verify required parameter 'ref' is not null or undefined
      assertParamExists('userAuthenticate', 'ref', ref);
      // verify required parameter 'pin' is not null or undefined
      assertParamExists('userAuthenticate', 'pin', pin);
      // verify required parameter 'state' is not null or undefined
      assertParamExists('userAuthenticate', 'state', state);
      const localVarPath = `/user/authenticate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (emailAddress !== undefined) {
        localVarQueryParameter['emailAddress'] = emailAddress;
      }

      if (ref !== undefined) {
        localVarQueryParameter['ref'] = ref;
      }

      if (pin !== undefined) {
        localVarQueryParameter['pin'] = pin;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {DomainCheckRequestBody} domainCheckRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDomainCheck: async (domainCheckRequestBody: DomainCheckRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'domainCheckRequestBody' is not null or undefined
      assertParamExists('userDomainCheck', 'domainCheckRequestBody', domainCheckRequestBody);
      const localVarPath = `/user/domain/check`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(domainCheckRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {InviteUserRequestBody} inviteUserRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInvite: async (inviteUserRequestBody: InviteUserRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'inviteUserRequestBody' is not null or undefined
      assertParamExists('userInvite', 'inviteUserRequestBody', inviteUserRequestBody);
      const localVarPath = `/user/invite`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(inviteUserRequestBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} state
     * @param {string} loginHint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSsoGenerateUrl: async (state: string, loginHint: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'state' is not null or undefined
      assertParamExists('userSsoGenerateUrl', 'state', state);
      // verify required parameter 'loginHint' is not null or undefined
      assertParamExists('userSsoGenerateUrl', 'loginHint', loginHint);
      const localVarPath = `/user/sso/generateUrl`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (loginHint !== undefined) {
        localVarQueryParameter['loginHint'] = loginHint;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} code
     * @param {string} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSsoSignIn: async (code: string, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('userSsoSignIn', 'code', code);
      // verify required parameter 'state' is not null or undefined
      assertParamExists('userSsoSignIn', 'state', state);
      const localVarPath = `/user/sso/signIn`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/version`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {ExternalAccountRequestBody} externalAccountRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async employeeExternalAccountProcessNewEmployee(
      externalAccountRequestBody: ExternalAccountRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalAccountResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.employeeExternalAccountProcessNewEmployee(externalAccountRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} employeeFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async employeeFileDownload(employeeFileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeFileDownloadResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFileDownload(employeeFileId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async employeeFileUpload(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmployeeFileUploadResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.employeeFileUpload(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ProbationReviewRequestBody} probationReviewRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async employeeProbationReviewCreate(
      probationReviewRequestBody: ProbationReviewRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProbationReviewResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.employeeProbationReviewCreate(probationReviewRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} fileMode
     * @param {ExportEmployeeRequestBody} exportEmployeeRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportEmployee(
      fileMode: string,
      exportEmployeeRequestBody: ExportEmployeeRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportEmployeeResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportEmployee(fileMode, exportEmployeeRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GoogleAccountListRequestBody} googleAccountListRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async externalAccountGoogleAccountList(
      googleAccountListRequestBody: GoogleAccountListRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleAccountListResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.externalAccountGoogleAccountList(googleAccountListRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GoogleCalendarCreateRequestBody} googleCalendarCreateRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async externalAccountGoogleCalendarCreate(
      googleCalendarCreateRequestBody: GoogleCalendarCreateRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleCalendarCreateResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.externalAccountGoogleCalendarCreate(googleCalendarCreateRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GoogleCalendarDeleteRequestBody} googleCalendarDeleteRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async externalAccountGoogleCalendarDelete(
      googleCalendarDeleteRequestBody: GoogleCalendarDeleteRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleCalendarDeleteResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.externalAccountGoogleCalendarDelete(googleCalendarDeleteRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {GoogleCalendarListRequestBody} googleCalendarListRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async externalAccountGoogleCalendarList(
      googleCalendarListRequestBody: GoogleCalendarListRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleCalendarListResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.externalAccountGoogleCalendarList(googleCalendarListRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async externalAccountGoogleOrgUnitList(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleOrgUnitListResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.externalAccountGoogleOrgUnitList(body, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} fileMode
     * @param {ImportEmployeeRequestBody} importEmployeeRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importEmployee(
      fileMode: string,
      importEmployeeRequestBody: ImportEmployeeRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importEmployee(fileMode, importEmployeeRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {ExportPerformanceReviewResultRequestBody} exportPerformanceReviewResultRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async performanceReviewResult(
      exportPerformanceReviewResultRequestBody: ExportPerformanceReviewResultRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportPerformanceReviewResultResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.performanceReviewResult(exportPerformanceReviewResultRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SpellCheckExistingRequestBody} spellCheckExistingRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spellCheckExisting(
      spellCheckExistingRequestBody: SpellCheckExistingRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpellCheckExistingResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spellCheckExisting(spellCheckExistingRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {SpellCheckWordRequestBody} spellCheckWordRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spellCheckWord(
      spellCheckWordRequestBody: SpellCheckWordRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpellCheckWordResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spellCheckWord(spellCheckWordRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {UserAccountAccessHistoryRequestBody} userAccountAccessHistoryRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAccountAccessHistory(
      userAccountAccessHistoryRequestBody: UserAccountAccessHistoryRequestBody,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAccountAccessHistoryResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAccountAccessHistory(userAccountAccessHistoryRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} emailAddress
     * @param {string} ref
     * @param {string} pin
     * @param {string} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userAuthenticate(emailAddress: string, ref: string, pin: string, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userAuthenticate(emailAddress, ref, pin, state, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {DomainCheckRequestBody} domainCheckRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userDomainCheck(domainCheckRequestBody: DomainCheckRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DomainCheckResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userDomainCheck(domainCheckRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {InviteUserRequestBody} inviteUserRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userInvite(inviteUserRequestBody: InviteUserRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userInvite(inviteUserRequestBody, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} state
     * @param {string} loginHint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSsoGenerateUrl(state: string, loginHint: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SSOGenerateUrlResponseBody>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSsoGenerateUrl(state, loginHint, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} code
     * @param {string} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async userSsoSignIn(code: string, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.userSsoSignIn(code, state, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async version(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Version200Response>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.version(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @param {ExternalAccountRequestBody} externalAccountRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeExternalAccountProcessNewEmployee(externalAccountRequestBody: ExternalAccountRequestBody, options?: any): AxiosPromise<ExternalAccountResponseBody> {
      return localVarFp.employeeExternalAccountProcessNewEmployee(externalAccountRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} employeeFileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeFileDownload(employeeFileId: string, options?: any): AxiosPromise<EmployeeFileDownloadResponseBody> {
      return localVarFp.employeeFileDownload(employeeFileId, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeFileUpload(options?: any): AxiosPromise<EmployeeFileUploadResponseBody> {
      return localVarFp.employeeFileUpload(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ProbationReviewRequestBody} probationReviewRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    employeeProbationReviewCreate(probationReviewRequestBody: ProbationReviewRequestBody, options?: any): AxiosPromise<ProbationReviewResponseBody> {
      return localVarFp.employeeProbationReviewCreate(probationReviewRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} fileMode
     * @param {ExportEmployeeRequestBody} exportEmployeeRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportEmployee(fileMode: string, exportEmployeeRequestBody: ExportEmployeeRequestBody, options?: any): AxiosPromise<ExportEmployeeResponseBody> {
      return localVarFp.exportEmployee(fileMode, exportEmployeeRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GoogleAccountListRequestBody} googleAccountListRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleAccountList(googleAccountListRequestBody: GoogleAccountListRequestBody, options?: any): AxiosPromise<GoogleAccountListResponseBody> {
      return localVarFp.externalAccountGoogleAccountList(googleAccountListRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GoogleCalendarCreateRequestBody} googleCalendarCreateRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleCalendarCreate(googleCalendarCreateRequestBody: GoogleCalendarCreateRequestBody, options?: any): AxiosPromise<GoogleCalendarCreateResponseBody> {
      return localVarFp.externalAccountGoogleCalendarCreate(googleCalendarCreateRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GoogleCalendarDeleteRequestBody} googleCalendarDeleteRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleCalendarDelete(googleCalendarDeleteRequestBody: GoogleCalendarDeleteRequestBody, options?: any): AxiosPromise<GoogleCalendarDeleteResponseBody> {
      return localVarFp.externalAccountGoogleCalendarDelete(googleCalendarDeleteRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {GoogleCalendarListRequestBody} googleCalendarListRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleCalendarList(googleCalendarListRequestBody: GoogleCalendarListRequestBody, options?: any): AxiosPromise<GoogleCalendarListResponseBody> {
      return localVarFp.externalAccountGoogleCalendarList(googleCalendarListRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    externalAccountGoogleOrgUnitList(body: object, options?: any): AxiosPromise<GoogleOrgUnitListResponseBody> {
      return localVarFp.externalAccountGoogleOrgUnitList(body, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} fileMode
     * @param {ImportEmployeeRequestBody} importEmployeeRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importEmployee(fileMode: string, importEmployeeRequestBody: ImportEmployeeRequestBody, options?: any): AxiosPromise<ResponseBody> {
      return localVarFp.importEmployee(fileMode, importEmployeeRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {ExportPerformanceReviewResultRequestBody} exportPerformanceReviewResultRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performanceReviewResult(exportPerformanceReviewResultRequestBody: ExportPerformanceReviewResultRequestBody, options?: any): AxiosPromise<ExportPerformanceReviewResultResponseBody> {
      return localVarFp.performanceReviewResult(exportPerformanceReviewResultRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SpellCheckExistingRequestBody} spellCheckExistingRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spellCheckExisting(spellCheckExistingRequestBody: SpellCheckExistingRequestBody, options?: any): AxiosPromise<SpellCheckExistingResponseBody> {
      return localVarFp.spellCheckExisting(spellCheckExistingRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {SpellCheckWordRequestBody} spellCheckWordRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spellCheckWord(spellCheckWordRequestBody: SpellCheckWordRequestBody, options?: any): AxiosPromise<SpellCheckWordResponseBody> {
      return localVarFp.spellCheckWord(spellCheckWordRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {UserAccountAccessHistoryRequestBody} userAccountAccessHistoryRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAccountAccessHistory(userAccountAccessHistoryRequestBody: UserAccountAccessHistoryRequestBody, options?: any): AxiosPromise<UserAccountAccessHistoryResponseBody> {
      return localVarFp.userAccountAccessHistory(userAccountAccessHistoryRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} emailAddress
     * @param {string} ref
     * @param {string} pin
     * @param {string} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userAuthenticate(emailAddress: string, ref: string, pin: string, state: string, options?: any): AxiosPromise<void> {
      return localVarFp.userAuthenticate(emailAddress, ref, pin, state, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {DomainCheckRequestBody} domainCheckRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userDomainCheck(domainCheckRequestBody: DomainCheckRequestBody, options?: any): AxiosPromise<DomainCheckResponseBody> {
      return localVarFp.userDomainCheck(domainCheckRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {InviteUserRequestBody} inviteUserRequestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userInvite(inviteUserRequestBody: InviteUserRequestBody, options?: any): AxiosPromise<InviteUserResponseBody> {
      return localVarFp.userInvite(inviteUserRequestBody, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} state
     * @param {string} loginHint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSsoGenerateUrl(state: string, loginHint: string, options?: any): AxiosPromise<SSOGenerateUrlResponseBody> {
      return localVarFp.userSsoGenerateUrl(state, loginHint, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} code
     * @param {string} state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    userSsoSignIn(code: string, state: string, options?: any): AxiosPromise<void> {
      return localVarFp.userSsoSignIn(code, state, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    version(options?: any): AxiosPromise<Version200Response> {
      return localVarFp.version(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {ExternalAccountRequestBody} externalAccountRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public employeeExternalAccountProcessNewEmployee(externalAccountRequestBody: ExternalAccountRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .employeeExternalAccountProcessNewEmployee(externalAccountRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} employeeFileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public employeeFileDownload(employeeFileId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .employeeFileDownload(employeeFileId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public employeeFileUpload(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .employeeFileUpload(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ProbationReviewRequestBody} probationReviewRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public employeeProbationReviewCreate(probationReviewRequestBody: ProbationReviewRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .employeeProbationReviewCreate(probationReviewRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} fileMode
   * @param {ExportEmployeeRequestBody} exportEmployeeRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public exportEmployee(fileMode: string, exportEmployeeRequestBody: ExportEmployeeRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .exportEmployee(fileMode, exportEmployeeRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GoogleAccountListRequestBody} googleAccountListRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public externalAccountGoogleAccountList(googleAccountListRequestBody: GoogleAccountListRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .externalAccountGoogleAccountList(googleAccountListRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GoogleCalendarCreateRequestBody} googleCalendarCreateRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public externalAccountGoogleCalendarCreate(googleCalendarCreateRequestBody: GoogleCalendarCreateRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .externalAccountGoogleCalendarCreate(googleCalendarCreateRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GoogleCalendarDeleteRequestBody} googleCalendarDeleteRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public externalAccountGoogleCalendarDelete(googleCalendarDeleteRequestBody: GoogleCalendarDeleteRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .externalAccountGoogleCalendarDelete(googleCalendarDeleteRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {GoogleCalendarListRequestBody} googleCalendarListRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public externalAccountGoogleCalendarList(googleCalendarListRequestBody: GoogleCalendarListRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .externalAccountGoogleCalendarList(googleCalendarListRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public externalAccountGoogleOrgUnitList(body: object, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .externalAccountGoogleOrgUnitList(body, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} fileMode
   * @param {ImportEmployeeRequestBody} importEmployeeRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public importEmployee(fileMode: string, importEmployeeRequestBody: ImportEmployeeRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .importEmployee(fileMode, importEmployeeRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {ExportPerformanceReviewResultRequestBody} exportPerformanceReviewResultRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public performanceReviewResult(exportPerformanceReviewResultRequestBody: ExportPerformanceReviewResultRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .performanceReviewResult(exportPerformanceReviewResultRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SpellCheckExistingRequestBody} spellCheckExistingRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public spellCheckExisting(spellCheckExistingRequestBody: SpellCheckExistingRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .spellCheckExisting(spellCheckExistingRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {SpellCheckWordRequestBody} spellCheckWordRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public spellCheckWord(spellCheckWordRequestBody: SpellCheckWordRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .spellCheckWord(spellCheckWordRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {UserAccountAccessHistoryRequestBody} userAccountAccessHistoryRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userAccountAccessHistory(userAccountAccessHistoryRequestBody: UserAccountAccessHistoryRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userAccountAccessHistory(userAccountAccessHistoryRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} emailAddress
   * @param {string} ref
   * @param {string} pin
   * @param {string} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userAuthenticate(emailAddress: string, ref: string, pin: string, state: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userAuthenticate(emailAddress, ref, pin, state, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {DomainCheckRequestBody} domainCheckRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userDomainCheck(domainCheckRequestBody: DomainCheckRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userDomainCheck(domainCheckRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {InviteUserRequestBody} inviteUserRequestBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userInvite(inviteUserRequestBody: InviteUserRequestBody, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userInvite(inviteUserRequestBody, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} state
   * @param {string} loginHint
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSsoGenerateUrl(state: string, loginHint: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userSsoGenerateUrl(state, loginHint, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} code
   * @param {string} state
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public userSsoSignIn(code: string, state: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .userSsoSignIn(code, state, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public version(options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .version(options)
      .then(request => request(this.axios, this.basePath));
  }
}
