import React, { Dispatch, FC, Fragment, ReactElement, ReactNode, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Location } from '@remix-run/router';
import { isFunction, isNil, isString } from 'lodash';

import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { IconProps } from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography, { TypographyProps } from '@mui/material/Typography';

import { JwtBody } from '../../services/jwtService';
import { Nullable } from '../../services/objectService';
import ShowIfNotEmpty from '../showIf/notEmpty';

import { Header_UserAccountQuery } from './__generated__';
import css from './index.module.scss';

interface BaseMenuListItem {
  title: string;
  browserTitle?: string;
  icon?: (active?: boolean, childrenCount?: number) => ReactElement<IconProps>;
  titleVariant?: TypographyProps['variant'];
  button?: boolean;
  visible?: (token: JwtBody | undefined, userAccount: Header_UserAccountQuery['currentUserAccount'] | undefined, location: Location) => boolean;
  disabled?: () => boolean;
  dense?: boolean;
  children?: Array<MenuListItem>;
}

interface TextMenuListItem extends BaseMenuListItem {
  url?: never;
  activeUrl?: never;
  onClickFunction?: never;
}

interface LinkMenuListItem extends BaseMenuListItem {
  url: string | RegExp;
  activeUrl?: string | RegExp;
  onClickFunction?: never;
}

interface FuncMenuListItem extends BaseMenuListItem {
  url?: never;
  activeUrl?: never;
  onClickFunction: () => void;
}

interface HiddenMenuListItem extends LinkMenuListItem {
  url: string | RegExp;
  activeUrl?: never;
  onClickFunction?: never;
  visible: () => false;
}

export type MenuListItem = TextMenuListItem | LinkMenuListItem | FuncMenuListItem | HiddenMenuListItem;

const MenuListItemComponent: FC<{ item: MenuListItem; hierarchyIndex: number; }> = ({ item, hierarchyIndex }) => {
  const refWrapper = useRef<HTMLDivElement>(null);
  const refText = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (!refWrapper.current || !refText.current) {
      return;
    }

    refText.current.style.fontSize = hierarchyIndex === 0 ? '16px' : '14px';

    while (refWrapper.current.getBoundingClientRect().height > 24) {
      refText.current.style.fontSize = `${ parseInt(refText.current.style.fontSize) - 0.1 }px`;
    }
  }, [hierarchyIndex]);

  return (
    <ListItemText ref={ refWrapper } sx={ { wordBreak: 'break-all' } }>
      <Typography ref={ refText } sx={ { lineHeight: `${ hierarchyIndex === 0 ? 24 : 20 }px` } } variant={ item.titleVariant ?? 'body1' }>{ item.title }</Typography>
    </ListItemText>
  );
};

const ListItemComponent: FC<{ children: ReactNode; childrenCount: number; item: MenuListItem; selected: boolean; setUserNameAnchorEl: Dispatch<SetStateAction<Nullable<Element>>> }> = props => {
  const className = useMemo(() => props.childrenCount === 0 ? css.menuHierarchyRoot : css.menuHierarchyChildren, [props.childrenCount]);
  const disabled = useMemo(() => props.item.disabled?.() ?? false, [props.item]);
  const isButton = useMemo(() => (isNil(props.item.button) ? true : props.item.button), [props.item.button]);

  const onMenuClick = useCallback(() => {
    if (disabled) {
      return;
    }

    if (isFunction(props.item.onClickFunction)) {
      props.item.onClickFunction();
    }
    props.setUserNameAnchorEl(null);
  }, [disabled, props]);

  if (isButton) {
    if (isString(props.item.url)) {
      return (
        <RouterLink style={ { textDecoration: 'none' } } to={ props.item.url }>
          <ListItemButton className={ className } dense={ props.item.dense } disableRipple={ true } selected={ props.selected }>{ props.children }</ListItemButton>
        </RouterLink>
      );
    }

    return <ListItemButton className={ className } dense={ props.item.dense } selected={ props.selected } onClick={ onMenuClick }>{ props.children }</ListItemButton>;
  } else {
    return <ListItem className={ className } dense={ props.item.dense }>{ props.children }</ListItem>;
  }
};

interface MenuListItemsProps {
  childrenCount: number,
  index: number;
  items: Array<MenuListItem>;
  keyPrefix: string;
  setUserNameAnchorEl: Dispatch<SetStateAction<Nullable<Element>>>;
  token: JwtBody | undefined;
  userAccount: Header_UserAccountQuery | undefined,
}

export const MenuListItemsComponent: FC<MenuListItemsProps> = props => {
  const location = useLocation();

  const getSelected = useCallback((item: MenuListItem) => {
    let result = false;
    const url = item.activeUrl ?? item.url;

    switch (typeof url) {
      case 'string':
        result = location.pathname.startsWith(url);
        break;
      case 'object':
        result = url.test(location.pathname);
        break;
    }

    return result;
  }, [location]);

  const sxEmptyIcon: SxProps = useMemo(() => ({ width: (props.childrenCount === 1 ? 40 * props.childrenCount : 40 + (props.childrenCount - 1) * 10) + 'px' }), [props.childrenCount]);

  return (
    <>
      {
        props.items
          .filter(item => !item.visible || item.visible(props.token, props.userAccount?.currentUserAccount, location))
          .map((item, idx) => {
            const selected = getSelected(item);

            return (
              <Fragment key={ `${ props.keyPrefix ? props.keyPrefix + '-' : '' }main${ props.index }-sub${ idx }` }>
                <ListItemComponent childrenCount={ props.childrenCount } item={ item } selected={ selected } setUserNameAnchorEl={ props.setUserNameAnchorEl }>
                  {
                    item.icon
                      ? (
                        <ListItemIcon sx={ { paddingLeft: `${ props.childrenCount * 10 }px`, marginRight: `${ props.childrenCount === 0 ? 16 : 10 }px` } }>
                          { item.icon(selected, props.childrenCount) }
                        </ListItemIcon>
                      )
                      : <Box sx={ sxEmptyIcon }>&nbsp;</Box>
                  }
                  <MenuListItemComponent hierarchyIndex={ props.childrenCount } item={ item } />
                </ListItemComponent>
                <ShowIfNotEmpty value={ item.children }>
                  {
                    children => (
                      <Box className={ [css.childrenWrapper, selected ? css.selected : ''].join(' ') }>
                        <MenuListItemsComponent childrenCount={ props.childrenCount + 1 }
                                                index={ props.index }
                                                items={ children }
                                                keyPrefix="children"
                                                setUserNameAnchorEl={ props.setUserNameAnchorEl }
                                                token={ props.token }
                                                userAccount={ props.userAccount }
                        />
                      </Box>
                    )
                  }
                </ShowIfNotEmpty>
              </Fragment>
            );
          })
      }
    </>
  );
};
