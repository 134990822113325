import { isNil } from 'lodash';

export interface Transposed {
  [dummy: string]: Array<any> | undefined;
}

export function getNumberArray(start: number, end: number): Array<number>;

export function getNumberArray(start: number, end: number, step: number): Array<{ start: number; end: number; }>;

export function getNumberArray(start: number, end: number, step?: number): Array<number> | Array<{ start: number; end: number; }> {
  if (typeof step === 'undefined') {
    return Array.from({ length: Math.abs(end - start) + 1 }, (v, i) => Math.min(start, end) + i);
  }

  const array: Array<{ start: number; end: number; }> = [];

  for (let i = start; i < end; i += step) {
    array.push({ start: i, end: i + step - 1});
  }

  return array;
}

export const transpose = <A extends Record<any, any>, T extends Transposed>(data: ReadonlyArray<A>, valueInterceptor?: (key: keyof A | string, value: any) => any | undefined) => {
  const result: Record<string, any> = {};

  for (const row of data) {
    for (const entries of Object.entries(row)) {
      if (typeof valueInterceptor === 'function') {
        const newValue = valueInterceptor(entries[0], entries[1]);

        if (newValue === undefined) {
          continue;
        }
        entries[1] = newValue;
      }
      result[entries[0]] = result[entries[0]] || [];
      result[entries[0]].push(entries[1]);
    }
  }

  return result as T;
};

export const countArrayElements = <T>(array: ReadonlyArray<T> | null | undefined): Array<{ value: T; count: number }> => {
  if (!array) {
    return [];
  }

  const result: Array<{ value: T; count: number }> = [];

  for (const value of array || []) {
    if (result.findIndex(f => f.value === value) === -1) {
      result.push({ value, count: array.filter(f => f === value).length });
    }
  }

  return result;
};

export const countArrayElementsUnsorted = <T>(array: ReadonlyArray<T>): Array<{ value: T; count: number }> => {
  const result: Array<{ value: T; count: number }> = [];

  for (const value of array) {
    if (result.length === 0 || result[result.length - 1].value !== value) {
      result.push({ value, count: 1 });
    } else {
      result[result.length - 1].count++;
    }
  }

  return result;
};

export const sortNullableString = (a: string | null | undefined, b: string | null | undefined): number => {
  if (a && !b) {
    return -1;
  } else if (!a && b) {
    return 1;
  } else if (a && b) {
    return a.localeCompare(b);
  }

  return 0;
};

export const getMedian = (array: Array<number>): number => {
  const half = Math.floor(array.length / 2);

  return array.length % 2 === 1 ? array[half] : (array[half - 1] + array[half]) / 2.0;
};

export const filterNil = <T>(array: Array<T | null | undefined>): Array<T> => array.filter(it => !isNil(it)) as Array<T>;

export const toArray = <T>(value: T | Array<T>): Array<T> => Array.isArray(value) ? value : [value];
