import { GraphQLError } from 'graphql';
import { createClient } from 'graphql-ws';
import { get, isEmpty, trim } from 'lodash';

import { ApolloClient, defaultDataIdFromObject, from, InMemoryCache, ServerError, ServerParseError, split } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

import { Configuration } from '../config';
import { setPopupMessage } from '../globalState';
import introspectionResult from '../graphql/introspection-result';

import { JwtBody } from './jwtService';
import { Nullable } from './objectService';
import { sendClientErrorToSlack } from './slackService';
import { LocalStorageService, SessionStorageService } from './storageService';

const JWT_ERRORS = ['Authorization header is not of the correct bearer scheme format.', 'jwt issuer invalid. expected: ', 'invalid token', 'invalid algorithm', 'jwt expired', 'invalid signature'];

export interface CommonNodeValue {
  nodeId?: string;
  dateCreated?: Nullable<string>;
  userCreated?: Nullable<string>;
  userAccountByUserCreated?: Nullable<{
    displayName: string;
  }>;
  dateUpdated?: Nullable<string>;
  userUpdated?: Nullable<string>;
  userAccountByUserUpdated?: Nullable<{
    displayName: string;
  }>;
  remark?: Nullable<string>;
}

export type Nodes<T> = {
  totalCount: number;
  nodes: ReadonlyArray<T & Readonly<CommonNodeValue>> | null;
};

export const apolloInMemoryCache = new InMemoryCache({
  dataIdFromObject: object => defaultDataIdFromObject({ __typename: object.__typename, id: object.nodeId, _id: object.nodeId }),
  possibleTypes: introspectionResult.possibleTypes,
});

const isServerError = (error: any): error is ServerError => error.name === 'ServerError';
const isServerParseError = (error: any): error is ServerParseError => error.name === 'ServerParseError';

export const getApolloClient = (
  getToken: () => JwtBody | undefined,
  getRawToken: () => string,
  onErrorOccurred: (args: { graphQLErrors: ReadonlyArray<GraphQLError> | undefined; networkError: NetworkError | undefined }) => void
) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let raiseOnErrorOccurs = false;

    for (const graphQLError of graphQLErrors ?? []) {
      if (['Subscription denied', 'Socket closed'].some(it => graphQLError.message.includes(it))) {
        return;
      }

      console.error(`GraphQL Error: ${ graphQLError.message }`, graphQLError);

      if (get(graphQLError.extensions, 'exception.routine') !== '_bt_check_unique') {
        raiseOnErrorOccurs = true;
        sendClientErrorToSlack({ type: 'GraphQLError', error: graphQLError }, getToken(), getRawToken()).then();
      }
    }

    if (networkError) {
      if (['Timeout exceeded', 'Failed to fetch', 'Socket closed'].some(it => networkError.message.includes(it))) {
        return;
      }

      console.error('Network Error', networkError);
      raiseOnErrorOccurs = true;
      sendClientErrorToSlack({ type: 'NetworkError', error: networkError }, getToken(), getRawToken()).then();
    }

    if (graphQLErrors) {
      if (graphQLErrors.some(({ message }) => JWT_ERRORS.some(x => x && message.startsWith(x)))) {
        // need to sign out forcibly
        LocalStorageService.clear();
        SessionStorageService.clear();
        document.cookie = '';
        window.location.reload();

        return;
      }
    }

    if (raiseOnErrorOccurs) {
      onErrorOccurred({ graphQLErrors, networkError });
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 3000,
      max: 5000,
      jitter: true,
    },
    attempts: {
      max: 20,
      retryIf: error => {
        if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
          setPopupMessage({ severity: 'error', message: 'A network error occurred. Please wait a moment and try again later.' });

          return false;
        } else if (isServerError(error)) {
          setPopupMessage({ severity: 'error', message: `A server error occurred (${ error.response.status }). Please wait a moment and try again later.` });

          return error.response.status < 500;
        } else if (isServerParseError(error)) {
          setPopupMessage({ severity: 'error', message: 'A server parse error occurred. Please contact to administrator if this happens again.' });

          return false;
        }

        return true;

        // setPopupMessage({ severity: 'error', message: 'An unknown error occurred. Please contact to administrator if this happens again.' });
        //
        // return false;
      },
    },
  });

  const batchHttpLink = new BatchHttpLink({
    uri: `${ Configuration.backendUrlBase }/graphql`,
  });

  const httpAuthLink = setContext((_, { headers }) => {
    const rawToken = getRawToken();

    return {
      headers: {
        ...headers,
        authorization: !isEmpty(trim(rawToken)) ? `Bearer ${ rawToken }` : undefined,
        accept: 'application/json',
      },
    };
  });

  const webSocketLink = new GraphQLWsLink(
    createClient({
      url: `${ Configuration.websocketUrlBase }/graphql`,
      connectionParams: () => {
        const rawToken = getRawToken();

        return { authorization: !isEmpty(trim(rawToken)) ? `Bearer ${ rawToken }` : undefined };
      },
    })
  );

  const webSocketAuthLink = setContext((_, { connectionParams }) => {
    const rawToken = getRawToken();

    return {
      connectionParams: {
        ...connectionParams,
        authorization: !isEmpty(trim(rawToken)) ? `Bearer ${ rawToken }` : undefined,
      },
    };
  });

  // const timeoutLink = new ApolloLinkTimeout(30000);

  return new ApolloClient({
    link: from([
      errorLink,
      retryLink,
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);

          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        // timeoutLink.concat(webSocketAuthLink.concat(webSocketLink)),
        // timeoutLink.concat(httpAuthLink.concat(batchHttpLink)),
        webSocketAuthLink.concat(webSocketLink),
        httpAuthLink.concat(batchHttpLink)
      ),
    ]),
    cache: apolloInMemoryCache,
    defaultOptions: {
      query: {
        notifyOnNetworkStatusChange: true,
      },
      watchQuery: {
        notifyOnNetworkStatusChange: true,
      },
    },
  });
};
