import React, { ReactElement, ReactNode } from 'react';

import { isFunction, isNil } from 'lodash';

interface ShowIfPropsStandard {
  children: ReactNode;
  test: boolean;
  value?: never;
}

interface ShowIfPropsTest<T> {
  children: (value: T) => ReactNode;
  test: (value: T) => boolean;
  value: T;
}

const ShowIf: <T extends any>(props: ShowIfPropsStandard | ShowIfPropsTest<T>) => ReactElement = ({ children, test, value }) => {
  if (!isFunction(test)) {
    if (!test) {
      return <></>;
    }

    return <>{ children }</>;
  } else if (!isNil(value) && isFunction(children)) {
    if (!test(value)) {
      return <></>;
    }

    return <>{ children(value) }</>;
  } else {
    console.error('[ShowIf] props inconsistent.');

    return <></>;
  }
};

export default ShowIf;
