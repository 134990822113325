import { createContext, useContext } from 'react';

import { JwtBody } from '../services/jwtService';

export interface TokenCookieContextProps {
  token: JwtBody | undefined;
  rawToken: string;
  setTokenToCookie: (rawToken: string) => void;
  removeTokenFromCookie: () => void;
}

export const TokenCookieContext = createContext<TokenCookieContextProps>({
  token: undefined,
  rawToken: '',
  setTokenToCookie: () => void 0,
  removeTokenFromCookie: () => void 0,
});

const useTokenCookie = () => useContext(TokenCookieContext);

export default useTokenCookie;
