import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Location } from '@remix-run/router';
import { last, uniqBy } from 'lodash';

import { parseUrlToLocationDescriptor, stringifyLocationDescriptor } from '../services/urlService';

interface StackItem extends Location {
  backToText: string;
}

const defaultPopItem = ({ ...parseUrlToLocationDescriptor('/dashboard'), backToText: 'dashboard' });

interface HeaderNavigationButtonProps {
  readonly isEmpty: boolean;
  readonly stack: ReadonlyArray<StackItem>;
  getLatestItem: () => StackItem | undefined;
  push: (location: Location | undefined, backToText: string) => void;
  back: () => void;
  clear: () => void;
}

const HeaderNavigationButtonContext = createContext<HeaderNavigationButtonProps>({
  isEmpty: true,
  stack: [],
  getLatestItem: () => undefined,
  push: () => void 0,
  back: () => void 0,
  clear: () => void 0,
});

export const HeaderNavigationButtonProvider = (props: { children: ReactNode }) => {
  const navigate = useNavigate();

  const [stack, setStack] = useState<Array<StackItem>>([]);

  const isEmpty = useMemo(() => stack.length === 0, [stack.length]);

  const push = useCallback((location: Location | undefined, backToText: string) => {
    if (!location) {
      return;
    }

    setStack(prev => {
      if (prev.some(it => stringifyLocationDescriptor(it) === stringifyLocationDescriptor(location))) {
        return prev;
      }

      return uniqBy([...prev, { ...location, backToText }], it => stringifyLocationDescriptor(it));
    });
  }, []);

  const getLatestItem = useCallback(() => last(stack), [stack]);

  const back = useCallback(() => navigate(stack.pop() ?? defaultPopItem), [navigate, stack]);
  const clear = useCallback(() => setStack([]), []);

  return <HeaderNavigationButtonContext.Provider value={ { isEmpty, stack, getLatestItem, push, back, clear } }>{ props.children }</HeaderNavigationButtonContext.Provider>;
};

const useHeaderNavigationButton = () => useContext(HeaderNavigationButtonContext);

export const ClearHeaderNavigationStack = (props: { children: ReactElement; }) => {
  const headerNavigationButton = useHeaderNavigationButton();

  const [processed, setProcessed] = useState(false);

  useEffect(() => {
    if (!processed) {
      headerNavigationButton.clear();
      setProcessed(true);
    }
  }, [headerNavigationButton, processed]);

  return props.children;
};

export default useHeaderNavigationButton;
