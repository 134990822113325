import { Location, To } from '@remix-run/router';
import { isEmpty, isNil, isString, trim, trimStart } from 'lodash';

import { isEmptySome, NullOrUndefinedOr } from './objectService';

export const parseUrlToLocationDescriptor = (url: string): Location => {
  /*
  example:
    https://med.anymindgroup.com/employee/list?page=1&quickFilter=ken#dummy
      -> return {
           pathname: '/employee/list',
           search: '?page=1&quickFilter=ken',
           hash: '#dummy'
         }
   */

  if (isEmpty(url)) {
    return { hash: '', pathname: '', search: '', state: undefined, key: '' };
  } else if (!/^https?:\/\//.test(url)) {
    url = 'https://example.com' + (url.startsWith('/') ? '' : '/') + url;
  }

  const { pathname, search, hash } = new URL(url);

  return { pathname, search, hash, state: undefined, key: '' };
};

export const stringifyLocationDescriptor = (location: To | undefined): string => {
  if (!location) {
    return '';
  } else if (isString(location)) {
    return location;
  }

  return location.pathname
    + (isNil(location.search) || isEmptySome(true, location.search) || location.search === '?' ? '' : `?${ trimStart(location.search, '?') }`)
    + (isNil(location.hash) || isEmptySome(true, location.hash) || location.hash === '#' ? '' : `#${ trimStart(location.hash, '#') }`);
};

export const parseUrl = (value: NullOrUndefinedOr<string>): { result: true; url: URL } | { result: false; url?: never; } => {
  if (isNil(value) || isEmpty(trim(value))) {
    return { result: false };
  }

  try {
    const url = new URL(value);

    return { result: true, url };
  } catch (e) {
    return { result: false };
  }
};
